import http from "./httpService";
import {url} from "./urlServices"

export function addListingSpace(data) {
  return http.post("/available_spaces", data);
}

export function editListingSpace(data, id) {
  return http.put(`/available_spaces/${id}`, data);
}
