import http from './httpService'
import {url} from "./urlServices"

//function to Add a Listing
export function addRentPlatfrom(formData, UploadProgress) {
  return http.post(`/rent_platfrom`, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  })
}

export function editRentPlatfrom(data, id, UploadProgress) {
  return http.put(`/rent_platfrom/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  })
}

export function editRentPlatfromResponse(data, id) {
  return http.put(`/rent_platfrom/response/${id}`, data)
}

export function getRentPlatfrom() {
  return http.get(`/rent_platfrom`)
}

export function getRentPlatfromById(id) {
  return http.get(`/rent_platfrom/${id}`)
}

export function deleteRentPlatfromById(id) {
  return http.delete(`/rent_platfrom/${id}`)
}
