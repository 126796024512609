import http from "./httpService";
import {url} from "./urlServices"

export function postTenant(data) {
  return http.post("/tenant", data);
}

export function editTenant(data, id) {
  return http.put(`/tenant/${id}`, data);
}

export function updateTenant() {
  return http.put("/tenant");
}

export function updateTenant2(id) {
  return http.put(`/tenant/tenant_update/${id}`);
}

export function updateTenantge_image(data) {
  return http.put("/tenant/tenant_image", data);
}

export function getTenantById(id) {
  return http.get(`/tenant/${id}`);
}

export function deletingTenantById(id) {
  return http.delete(`/tenant/${id}`);
}

export function getTenantDetailsById(id) {
  return http.get(`/tenant/tenant_details/${id}`);
}

export function postTenantToDeleteOthers(data) {
  return http.post(`/tenant/tenantToDeleteAndLeases`, data);
}
