import http from "./httpService";
import jwtDecode from "jwt-decode";
import {url} from "./urlServices.js"

http.setJwt(getJwt());

export function getJwt() {
  return localStorage.getItem("token");
}

export function loginWithJwt(jwt) {
  localStorage.setItem("token", jwt);
}

export function login(data) {
  return http.post(`/auth`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function loginNowWithGoogle(data) {
  return http.post("/auth/loginWithGoogle", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function loginNowWithFacebook(data) {
  return http.post("/auth/loginWithFacebook", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function logout() {
  localStorage.removeItem("token");
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    return jwt && jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function userConfirmPassword(data) {
  try {
    return http.post("/auth/compare_paid", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (ex) {}
}

const auth = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};

export default auth;
