import http from "./httpService"
import {url} from "./urlServices.js"

export function  ProfileImages(id, formData){
const configs = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
return http.put(`/upload/${id}`, formData, configs)
}
