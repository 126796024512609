import React from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useTranslation } from "react-i18next";
import "./addListing.css";

const AddressInput = ({
  settingLocationFromAddress,
  value,
  settingStreetAddress,
}) => {
  const { t } = useTranslation();
  //usePlaceAutocomplete functions to get data
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 15.361167, lng: () => 18.664479 },
      radius: 1,
    },
  });

  return (
    <div>
      <label className="addListLabel">
        {t("Address*")}
        <span style={{ fontSize: "8px", color: "orange" }}>
          ({t("click_autosuggest")})
        </span>
      </label>

      <Combobox
        onSelect={async (address) => {
          const result = await getGeocode({ address });
          const { lat, lng } = await getLatLng(result[0]);

          settingLocationFromAddress({
            loaded: true,
            coordinates: {
              lat,
              lng,
            },
          });
          settingStreetAddress(address);
          clearSuggestions();
        }}
      >
        <ComboboxInput
          value={value}
          onChange={async (e) => {
            try {
              e.preventDefault();
              setValue(e.target.value);
              settingStreetAddress(e.target.value);
            } catch (error) {
              console.log(error);
            }
          }}
          disabled={!ready}
          placeholder="eg place,town, country"
          className="addListingInput"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default AddressInput;
