import http from "./httpService";
import { saveAs } from "file-saver";
import logger from "./loggerService"
import {url} from "./urlServices.js"

export function postStatus(data) {
  return http.post("/status", data);
}

export function getStatus() {
  return http.get("/status");
}

export function getTenantsClosingDeal() {
  return http.get("/status/tenant_close_deal");
}

export function getLandlordsClosingDeal() {
  return http.get("/status/landlord_close_deal");
}

export function putStatus() {
  return http.put("/status");
}
export function putStatus2(id) {
  return http.put(`/status/tenant/${id}`);
}

export function putTenantRejectCancel(data) {
  return http.put("/status/tenant_reject_cancel", data);
}
export function putLandlordRejectCancel(data) {
  return http.put("/status/landlord_reject_cancel", data);
}

export function getStatusByApplicantsId(id) {
  return http.get(`/status/statuses/${id}`);
}

export function getUnderReviewStatusByTenantId(id) {
  return http.get(`/status/underReview/${id}`);
}

export function getApprovedStatusByTenantId() {
  return http.get("/status1");
}

export function getApprovedStatusByTenantId1(id) {
  fetch(`https://nawafrica.com/status/approved/${id}`, {
    method: "GET", // default, so we can ignore
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function updateStatusForAnApplicant(id) {
  return http.put(`/status/lease_sign/${id}`);
}

export function deleteAStatus(id) {
  return http.delete(`/status/underReview/${id}`);
}
export function deleteAStatusTotally(id) {
  return http.delete(`/status/underReviewTotally/${id}`);
}

export function deleteASignLease(id) {
  return http.delete(`/status/lease_sign/${id}`);
}

export function getASignLeaseById(id) {
  return http.get(`/status/lease_sign/${id}`);
}

// get/status/sign_lease/:id
export function getALeaseSignById(id) {
  return http.get(`/status/sign_lease/${id}`);
}

export function deleteAllStatus(data) {
  return http.post(`/status/deleteAllStatus`, data);
}

export function getStatusOfTenant(listingID, tenantID) {
  return http.get(`/status/listing/${listingID}/${tenantID}`);
}

export function updateClientLeaseStartDate(data) {
  return http.put("/status/edit_lease_start", data);
}

export function postAndDownloadAgreementService(
  applicationId,
  name,
  setFinalise
) {
  http
    .post(
      `/createPDF/tenant/download`,
      { applicationId },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${name}LeaseSign.pdf`);

      setFinalise("");
    })
    .catch((err) => {
      logger.log(
        "this is the error from getting blob in downloadAgreement",
        err
      );
    });
}

//@ROUTE delete/status/application/:id
export function setApplicationToDelete(id) {
  return http.delete(`/status/application/${id}`);
}

export function getAllApplicationStatusOwing() {
  return http.get("/status/landlord_amount_owed");
}

export function updateLeaseEndDate(leaseID, data) {
  return http.put(`/status/end_date/${leaseID}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
