import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setConfig, buildUrl } from "cloudinary-build-url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Card from "@mui/material/Card";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import SelectedItem1 from "./SelectedItem1";
import PriceInput from "./PriceInput";
import selectedCountryInputs from "./../../Utils/translateInput";

import "./styleCompo.css";
import { trackEvent } from "../../Utils/useGAEventTracker";
import { getCurrentUser } from "../../Services/authService";
import TextInputKeyword from "./TextInputKeyword";

setConfig({
  cloudName: "dfbtdeliu",
});

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

const HeaderSearch = ({
  title,
  homeTitle,
  itemsCountry,
  rentOrSell,
  latestItem,
  itemsState,
  itemsCity,
  itemsBedroom,
  itemsListingType,
  itemsCurrency,
  itemsKeyword,
  onChangeCountry,
  onChangeState,
  onChangeCity,
  onChangeListingType,
  onChangeBedroom,
  onChangeKeyword,
  onChangePrice,
  onChangeCurrency,
  valueCountry,
  valueState,
  valueCity,
  valueBedroom,
  valueListingType,
  valueKeyword,
  valuePrice,
  selectedAfricaCountry,
  selectedState,
  selectedCity,
  selectedBedroom,
  selectedListing,
  selectedKeyword,
  price,
  numberOfUnreadMsgs,
  onClickMessage,
  availableSpaces,
  onChangeListingTitle,
  valueTitle,
}) => {
  const { t } = useTranslation();

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const myUrlPresented = (url) => {
    const urlArray = url.split("fi");
    const firstArray = urlArray[0];
    const remainingFirstArray = firstArray.slice(0, firstArray.length - 1);
    const joining = remainingFirstArray + "/";
    return `url(/${joining}fi${urlArray[1]})`;
  };

  return (
    <>
      <div className="headerSearchContainer">
        {getCurrentUser() && (
          <div variant="extended" className="recievedMessageCon">
            <IconButton
              onClick={onClickMessage}
              aria-label={notificationsLabel(100)}
            >
              <Badge badgeContent={numberOfUnreadMsgs} color="secondary">
                <MailIcon sx={{ fontSize: 40, color: "white" }} />
              </Badge>
            </IconButton>
          </div>
        )}

        <div
          id="carouselExampleSlidesOnly"
          className="carousel"
          data-ride="carousel"
          data-interval="10000"
        >
          {latestItem.length > 0 && (
            <>
              <div className="carousel-inner">
                {latestItem.map((item, index) => {
                  const src = buildUrl(
                    item.main_photo &&
                    item.main_photo.path &&
                    item.main_photo.path.url,
                    {
                      transformations: {
                        resize: {
                          type: "scale",
                          width: 1200,
                          height: 600,
                        },
                      },
                    }
                  );

                  return (
                    <div
                      key={`${item.id}`}
                      className={
                        index === 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      {item.main_photo &&
                        item.main_photo.path &&
                        item.main_photo.path.public_id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "lightgray",
                            height: "100vh",
                          }}
                        >
                          <img
                            style={{ objectFit: "cover", height: "100vh" }}
                            crossOrigin="anonymous"
                            className="animate__animated"
                            src={src}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "lightgray",
                          }}
                        >
                          <img
                            style={{ objectFit: "cover" }}
                            crossorigin="anonymous"
                            className="animate__animated"
                            src={
                              item.main_photo &&
                              item.main_photo.path &&
                              item.main_photo.path.url
                            }
                          />
                        </div>
                      )}
                      <div className="carousel-caption carousel-captionForSearch d-none d-md-block">
                        <p className="animate__animated animate__bounceInDown">
                          <Link
                            onClick={() =>
                              localStorage.setItem("portalOrManagement", "")
                            }
                            className="headerSearchAtag"
                            to={`/listing/${item.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <b>
                              {capitalize(
                                item.title.length > 14
                                  ? item.title.slice(0, 14) + "..."
                                  : item.title
                              )}
                            </b>
                            ,
                            {t("header_search_carousel_link", {
                              numberOfBedrooms:
                                item.listing_type !== "Land"
                                  ? `${item.bedrooms}`
                                  : "",
                              s: Number(item.bedrooms) > 1 ? "s" : "",
                              listing_type: t(item.listing_type),
                              town: item.city,
                              country: selectedCountryInputs(item.country),
                              rent_or_sell: selectedCountryInputs(
                                item.rent_or_sell
                              ),
                            })}
                            ,{" "}
                            {item?.remainingSpace === 0
                              ? "No"
                              : item?.remainingSpace}{" "}
                            {t("available quantity remaining")}
                          </Link>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              {latestItem.length > 1 && (
                <>
                  <a
                    className="carousel-control-prev previousCarouselBtn"
                    href="#carouselExampleSlidesOnly"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next nextCarouselBtn"
                    href="#carouselExampleSlidesOnly"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </>
              )}
            </>
          )}

          {latestItem.length === 0 && (
            <div className="carousel-inner">
              <div
                style={{ backgroundColor: "lightgray" }}
                className="carousel-item active  headerSearchVideoCon"
              >
                <video
                  className="animate__animated"
                  style={{ width: "100%", height: "100%" }}
                  controls
                  autoPlay
                  muted
                  playsInline={false}
                  loop
                >
                  <source src="/images/estate-video.mp4" type="video/mp4" />
                </video>
                {/* <img
                style={{ objectFit: "cover", width: "100%" }}
                className="animate__animated"
                src="/images/image1.jpg"
                alt="Third slide"
              /> */}
              </div>

              {/* <div
              style={{ backgroundColor: "lightgray" }}
              className="carousel-item"
            >
              <img
                style={{ width: "100%" }}
                className="animate__animated"
                src="/images/image2.jpg"
                alt="Second slide"
              />
            </div> */}

              {/* <div
              style={{ backgroundColor: "lightgray" }}
              className="carousel-item"
            >
              <img
                style={{ width: "100%" }}
                className="animate__animated"
                src="/images/image3.jpg"
                alt="Second slide"
              />
            </div> */}
              {/* <div
              style={{ backgroundColor: "lightgray" }}
              className="carousel-item"
            >
              <img
                style={{ width: "100%", objectFit: "cover" }}
                className="animate__animated"
                src="/images/image4.jpg"
                alt="First slide"
              />
            </div> */}
            </div>
          )}

          {/* {latestItem.length === 0 && (
          <>
            <a
              className="carousel-control-prev previousCarouselBtn"
              href="#carouselExampleSlidesOnly"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next nextCarouselBtn"
              href="#carouselExampleSlidesOnly"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </>
        )} */}
        </div>

        <Card
          variant="outlined"
          sx={{ background: "rgba(192,192,192,0.9)" }}
          className="headerSearchInputContainer"
        >
          <div className="headerInputsCon">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                  className="headerTitleConh1"
                >
                  {homeTitle}
                </h2>
                <p
                  className="headerTitleConP"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                >
                  <span className="RentOrSellProp headerTitleConRentOrSellPropB">
                    <b>{t("RENT_OR_BUY")}</b>
                  </span>
                  <span className="span">{t("headerSearch_listingType")}</span>
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="searchCountryCon">
                  <SelectedItem1
                    placeholder={t("Choose a Country")}
                    search={"country"}
                    items={itemsCountry}
                    onChange={onChangeCountry}
                    value={valueCountry}
                    width="90%"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="searchStateCon">
                  <SelectedItem1
                    placeholder={t("state/region")}
                    search={"state"}
                    items={itemsState}
                    onChange={onChangeState}
                    value={valueState}
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="searchCityCon">
                  <SelectedItem1
                    placeholder={t("city/town")}
                    search={"city"}
                    items={itemsCity}
                    onChange={onChangeCity}
                    value={valueCity}
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="listingTypeCon">
                  <SelectedItem1
                    placeholder={t("Listing_Type_(Any)")}
                    search={"listingType"}
                    items={itemsListingType}
                    onChange={onChangeListingType}
                    value={valueListingType}
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div style={{ marginTop: 12 }} className="searchBedroomCon">
                  <SelectedItem1
                    placeholder={t("Bedroom_(Any)")}
                    search={"bedroom"}
                    items={itemsBedroom}
                    onChange={onChangeBedroom}
                    value={valueBedroom}
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="searchKeywordCon">
                  <TextInputKeyword
                    items={itemsKeyword}
                    placeholder={t("keywords_(Any)")}
                    keyword={valueKeyword}
                    handleChange={onChangeKeyword}
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="searchTitleConing">
                  <PriceInput
                    // items={itemsCurrency}
                    onChangePrice={onChangeListingTitle}
                    // onChangeCurrency={onChangeCurrency}
                    value={valueTitle}
                    price={false}
                    type="text"
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginTop: 7,
                  }}
                  className="searchCurrencyCon"
                >
                  <PriceInput
                    items={itemsCurrency}
                    onChangePrice={onChangePrice}
                    onChangeCurrency={onChangeCurrency}
                    value={valuePrice}
                    width="90%"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="searchBtnCon">
                  <Link
                    className="btn searchButton"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis"
                    }}
                    onClick={() =>
                      trackEvent(
                        "search listing on Map",
                        "/contactUsNow",
                        "/search_listing?"
                      )
                    }
                    to={`/search_listing?country=${selectedAfricaCountry}&state=${selectedState}&city=${selectedCity}&bedrooms=${selectedBedroom}&keyword=${valueKeyword}&listing_type=${selectedListing}&price=${price}&rentOrSell=${rentOrSell}&title=${title}`}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    {t("headerSearch_btn")}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <div className="row">
          <div className="headerTitleCon form-group col-lg-12 ">
            <h1>{homeTitle}</h1>
            <p>
              <span className="RentOrSellProp">
                <b>{t("RENT_OR_BUY")}</b>
              </span>
              <span className="span">{t("headerSearch_listingType")}</span>
            </p>
          </div>

          <div className="col-lg-12 searchCountryCon">
            <SelectedItem1
              placeholder={t("Choose a Country")}
              search={"country"}
              items={itemsCountry}
              onChange={onChangeCountry}
              value={valueCountry}
            />
          </div>

          <div className="col-lg-12 col-md-12  searchStateCon">
            <SelectedItem1
              placeholder={t("state/region")}
              search={"state"}
              items={itemsState}
              onChange={onChangeState}
              value={valueState}
            />
          </div>

          <div className="col-lg-12 col-md-12 searchCityCon">
            <SelectedItem1
              placeholder={t("city/town")}
              search={"city"}
              items={itemsCity}
              onChange={onChangeCity}
              value={valueCity}
            />
          </div>

          <div className="col-lg-12 col-md-12 searchBedroomCon">
            <SelectedItem1
              placeholder={t("Bedroom_(Any)")}
              search={"bedroom"}
              items={itemsBedroom}
              onChange={onChangeBedroom}
              value={valueBedroom}
            />
          </div>

          <div
            className="col-lg-12 col-md-12 listingTypeCon"
          >
            <SelectedItem1
              placeholder={t("Listing_Type_(Any)")}
              search={"listingType"}
              items={itemsListingType}
              onChange={onChangeListingType}
              value={valueListingType}
            />
          </div>

          <div className="col-lg-12 col-md-12 searchCurrencyCon">
            <PriceInput
              items={itemsCurrency}
              onChangePrice={onChangePrice}
              onChangeCurrency={onChangeCurrency}
              value={valuePrice}
            />
          </div>

          <div className="col-lg-12 col-md-12 searchKeywordCon">
            <TextInputKeyword
              items={itemsKeyword}
              placeholder={t("keywords_(Any)")}
              keyword={valueKeyword}
              handleChange={onChangeKeyword}
            />
          </div>

          <div className="searchBtnCon">
            <Link
              className="btn searchButton"
              onClick={() =>
                trackEvent(
                  "search listing on Map",
                  "/contactUsNow",
                  "/search_listing?"
                )
              }
              to={`/search_listing?country=${selectedAfricaCountry}&state=${selectedState}&city=${selectedCity}&bedrooms=${selectedBedroom}&keyword=${valueKeyword}&listing_type=${selectedListing}&price=${price}&rentOrSell=${rentOrSell}`}
            >
              <span style={{ marginRight: "5px" }}>
                <FontAwesomeIcon icon={faSearch} />
              </span>
              {t("headerSearch_btn")}
            </Link>
          </div>
        </div> */}
        </Card>
      </div>
    </>
  );
};

export default HeaderSearch;
