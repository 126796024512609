import { setConfig, buildUrl } from 'cloudinary-build-url'
import logger from '../Services/loggerService'


setConfig({
  cloudName: 'dfbtdeliu',
})

export function Nothumb(width, height, image) {
  const src = buildUrl(image, {
    // cloud: {
    //   storageType: 'fetch',
    // },
    transformations: {
      resize: {
        type: 'scale',
        width,
        height,
      },
    },
  })

  logger.log('here are the source', src)

  return src
}

export function thumb(width = 100, height = 100, image) {
  const src = buildUrl(
    { image },
    {
      cloud: {
        storageType: 'thumb',
      },
      transformations: {
        resize: {
          type: 'fill',
          width,
          height,
        },
        gravity: 'face',
        zoom: '0.8',
      },
    }
  )

  return src
}
