import http from './httpService'
import {url} from "./urlServices.js"

export function tenantAddPaymentOption(data) {
  return http.post('/tenant_payment', data, {
    'Content-Type': 'application/json',
  })
}

export function tenantUpdatePaymentOption(data) {
  return http.put('/tenant_payment', data, {
    'Content-Type': 'application/json',
  })
}

export function getAllUsersPaymentMethods() {
  return http.get('/tenant_payment')
}

export function DeleteAPaymentMethodById(id) {
  return http.delete(`/tenant_payment/${id}`)
}
