import React, { useState, useEffect, useContext } from "react";
import logger from "../Services/loggerService";
import _ from "lodash";
import io from "socket.io-client";
import { Image } from "cloudinary-react";
import { FaUser } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import ChatManagement from "./../components/ManageTenants/ChatManagement";
import SearchPaymentTenant from "../components/ManageTenantsDet/SearchPaymentTenant";
import TableTenantPayment from "../components/ManageTenantsDet/TableTenantPayment";
import EditPaymentModal from "../components/ManageTenantsDet/EditPaymentModal";

import {
  clientGetAvailableSpaces,
  getListingsById,
} from "../Services/listings";
import { getUserById } from "../Services/registerService";
import { getTenantById, getTenantDetailsById } from "../Services/tenantService";
import {
  getPaymentByListing_id,
  getPaymentDetailsPayerById,
  getPaymentLeaseId,
  postAndDownloadPaymentService,
} from "../Services/payment";
import { myDateToSort } from "../Utils/currentDate";
import Meta from "./../Utils/Meta";

import UnreadMsgsContext from "./../unreadmessages/context";
import ListingInfo from "./../components/LeaseSignDetails/ListingInfo";
import { getCurrentUser, userConfirmPassword } from "./../Services/authService";
import {
  getALeaseSignById,
  postAndDownloadAgreementService,
  putTenantRejectCancel,
  updateLeaseEndDate,
  updateClientLeaseStartDate,
} from "../Services/applicationStatus";
import { getASignLeaseById } from "./../Services/applicationStatus";
import { getLang } from "../Utils/getLang";
import { trackEvent } from "../Utils/useGAEventTracker";
import CancelAgreementAlert from "../components/LeaseSignDetails/CancelAgreementAlert";
import CloseDeal from "../components/LeaseSignDetails/CloseDeal";
import DebtFree from "../components/Leases/DebtFree";
import OwingAnimation from "../components/Leases/OwingAnimation";
import AlertCustomise from "../components/Home/AlertCustomise";
import Sidebar from "../components/AddListing/Sidebar";
import OpenButton from "../components/AddListing/OpenButton";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import Meta1 from "../Utils/Meta1";
import EditLeaseEndDate from "../components/LeaseSignDetails/EditLeaseEndDate";
import {
  Badge,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";

const ManageTenantDetail = ({ match, history }) => {
  const { t } = useTranslation();

  const [path, setPath] = useState("");
  const [close, setClose] = useState(true);

  const [underConstruction, setUnderConstruction] = useState(false);

  const [tenant, setTenant] = useState({});
  const [tenantRegisterDetails, setTenantRegisterDetails] = useState({});

  const [myPersonalData, setMyPersonalData] = useState({});

  const [listing, setListing] = useState({});

  const [payments, setPayments] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const [selectedPaymentToLandlord, setSelectedPaymentToLandlord] = useState(
    {}
  );
  const [selectedPaymentTable, setSelectedPaymentTable] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [loadMyData, setLoadMyData] = useState(false);

  const [loadingSearchPayment, setLoadingSearchPayment] = useState(false);

  const [detailLeaseSign, setDetailLeaseSign] = useState({});

  const [mySignLease, setMySignLease] = useState({});

  const [addPay, setAddPay] = useState("add pay");

  const [subtractPay, setSubtractPay] = useState("");

  const [amountToEdit, setAmountToEdit] = useState("");

  const [editError, setEditError] = useState("");

  const [onloadEdit, setOnloadEdit] = useState(false);

  const [editedSent, setEditedSent] = useState(false);

  const [socket, setSocket] = useState();

  const { unreadMsgs, setTenants: setTening } = useContext(UnreadMsgsContext);

  const [requestToClose, setRequestToClose] = useState(false);
  const [closeDeal, setCloseDeal] = useState(false);
  const [rejectCancel, setRejectCancel] = useState(false);
  const [upSideBar, setUpSideBar] = useState(false);

  const [loadingCloseDeal, setLoadingCloseDeal] = useState(false);

  const [listingAnalysis, setListingAnalysis] = useState({
    payment_det: [],
    payments: [],
  });

  const [open, setOpen] = useState(false);
  const [updateEndDate, setUpdateEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [loadingEndDate, setLoadingEndDate] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPasswordData, setConfirmPasswordData] = useState("");
  const [chances, setChances] = useState(10);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorConfirmEdit, setErrorConfirmEdit] = useState("");

  const [availableSpace, setAvailableSpace] = useState([]);
  const [tenantSpace, setTenantSpace] = useState({});
  const [loadDownloadAgreement, setLoadDownloadAgreement] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [openEditStartDate, setOpenEditStartDate] = useState(false);
  const [editStartingDate, setEditStartingDate] = useState("");
  const [editStartingDateError, setEditStartingDateError] = useState("");
  const [loadingEditStartDate, setLoadingEditStartDate] = useState(false);

  useEffect(() => {
    setUnderConstruction(false);
    getAllDatasForTenant();
    localStorage.setItem("portalPath", "/portal/tenants");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    const socketing = io();
    socketing.on(`${getCurrentUser().id}resultToEdit`, (data) => {
      setMySignLease(data);
    });

    socketing.on(`${getCurrentUser().id}resultToEdited`, (data) => {
      setMySignLease(data);
      alert(`Confirm Edit`);
    });

    socketing.on(
      `${getCurrentUser() && getCurrentUser().id}tenant_cancelled_agreement`,
      (data) => {
        if (data.reject) {
          setRejectCancel(true);
          setInterval(() => {
            setRejectCancel(false);
          }, 15000);
        }
        setTenant(data.tenant);
        setMySignLease(data.applSign);
        if (data.tenant.tenant_close_deal && !data.tenant.landlord_close_deal) {
          setRequestToClose(true);
        } else {
          setRequestToClose(false);
        }

        if (data.tenant.tenant_close_deal && data.tenant.landlord_close_deal) {
          setCloseDeal(true);
        } else {
          setCloseDeal(false);
        }
      }
    );
    setSocket(socketing);
    return () => {
      localStorage.setItem("managementPath", "");
    };
  }, []);

  const getAllDatasForTenant = async () => {
    const ID = match.params.id;
    try {
      setLoadMyData(true);

      const { data: theTenant } = await getTenantDetailsById(ID);
      setTenant(theTenant);
      if (theTenant.tenant_close_deal && !theTenant.landlord_close_deal) {
        setRequestToClose(true);
      } else {
        setRequestToClose(false);
      }

      if (theTenant.tenant_close_deal && theTenant.landlord_close_deal) {
        setCloseDeal(true);
      } else {
        setCloseDeal(false);
      }

      const { data: tenantDet } = await getUserById(theTenant.tenant_id);
      setTenantRegisterDetails(tenantDet);

      const { data: me } = await getUserById(
        getCurrentUser() && getCurrentUser().id
      );
      setMyPersonalData(me);

      const { data: theListing } = await getListingsById(theTenant.listing_id);
      setListing(theListing);

      const { data: leaseSigned } = await getALeaseSignById(
        theTenant.application_id
      );

      const { data: pays } = await getPaymentLeaseId(leaseSigned.id);
      const hisPay = pays.filter(
        (pay) => pay.listing_id === theTenant.listing_id
      );
      const sortedPayments = _.orderBy(
        hisPay,
        [sortColumn.path],
        [sortColumn.order]
      );
      setPayments(sortedPayments);

      const { data: pays_detail } = await getPaymentDetailsPayerById(
        theTenant.tenant_id
      );
      setPaymentDetails(pays_detail);

      const { data: detailLease } = await getALeaseSignById(
        theTenant.application_id
      );
      setDetailLeaseSign(detailLease);

      const { data: signLease } = await getASignLeaseById(
        theTenant.application_status_sign_id
      );
      setMySignLease(signLease);

      if (theTenant.tenant_reject_cancel) {
        setRejectCancel(true);
        setInterval(() => {
          setRejectCancel(false);
        }, 15000);
      } else {
        setRejectCancel(false);
      }

      await putTenantRejectCancel({
        applSign: signLease,
        leaseSign: detailLease,
        tenant: theTenant,
      });

      const { data: listingPayment } = await getPaymentByListing_id(
        theListing.id
      );
      setListingAnalysis(listingPayment);

      const { data: spaces } = await clientGetAvailableSpaces(theListing.id);
      setAvailableSpace(spaces);

      for (let i = 0; i < spaces.length; i++) {
        if (
          spaces[i].all_occupants.some(
            (occ) => occ.occupant_id === tenantDet.id
          )
        ) {
          setTenantSpace(spaces[i]);
          break;
        } else {
        }
      }

      setLoadMyData(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        history.push("/notFound");
        logger.log(
          "the error in getMessageData function in ManageMessageDet component",
          ex.response.data
        );
      }
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to upon the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function involve in searching payment
  const onChangeSearchAmount = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableAmount(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);

        const { data: leaseSigned } = await getALeaseSignById(
          detailLeaseSign.application_id
        );

        const { data: pays } = await getPaymentLeaseId(leaseSigned.id);
        const hisPay = pays.filter(
          (pay) => pay.listing_id === tenant.listing_id
        );
        const sortedPayments = _.orderBy(
          hisPay,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableAmount = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value?.toLowerCase();
      let title = data[i]?.amount?.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //filter payment by the date paid
  const onChangeSearchPayDate = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableCreatedDate(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: leaseSigned } = await getALeaseSignById(
          detailLeaseSign.application_id
        );

        const { data: pays } = await getPaymentLeaseId(leaseSigned.id);
        const hisPay = pays.filter(
          (pay) => pay.listing_id === tenant.listing_id
        );
        const sortedPayments = _.orderBy(
          hisPay,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableCreatedDate = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value?.toLowerCase();
      // const myValue= myDateToSort(value)
      let title = data[i]?.create_date?.toLowerCase();
      const myTitle = myDateToSort(title);
      if (myTitle.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //search payment by their payment method either mobile money or paypal or momo
  const onChangeSearchPaymentMethod = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTablePaymentMethod(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: leaseSigned } = await getALeaseSignById(
          detailLeaseSign.application_id
        );

        const { data: pays } = await getPaymentLeaseId(leaseSigned.id);
        const hisPay = pays.filter(
          (pay) => pay.listing_id === tenant.listing_id
        );
        const sortedPayments = _.orderBy(
          hisPay,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTablePaymentMethod = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value?.toLowerCase();
      let title = data[i]?.payment_method?.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function to sort payment base on date amount and payment method
  const onChangeSort = (e) => {
    e.preventDefault();
    const paying = [...payments];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedPayment = _.orderBy(paying, [column.path], [column.order]);
    setPayments(sortedPayment);
  };

  //function to download the receipt
  const ondownloadReceipt = async (payment) => {
    try {
      trackEvent(
        "download payment receipt",
        `/createPDF/payment/${payment.id}`,
        "download payment receipt"
      );
      setSelectedPaymentToLandlord(payment);
      await postAndDownloadPaymentService(payment, (data) =>
        setSelectedPaymentToLandlord(data)
      );
    } catch (error) {
      logger.log(error);
    }
  };

  //function to execute drop to show payment details
  const onClickPayment = (payment) => {
    trackEvent(
      "dropdown to show payment ",
      `${payment.id}`,
      "dropdown payment btn manage"
    );
    const myPays = [...selectedPaymentTable];
    if (myPays.includes(payment)) {
      const remainingPay = myPays.filter((pay) => pay !== payment);
      setSelectedPaymentTable(remainingPay);
    } else {
      myPays.push(payment);
      setSelectedPaymentTable(myPays);
    }
  };

  //function to get the date the leasdetailses starts the date it ends
  const dateLeaseStartAndExpiry = () => {
    const datee = detailLeaseSign.start_date
      ? new Date(detailLeaseSign.start_date.slice(0, 10))
      : new Date();
    const yearLeaseStart = datee.getFullYear();
    const monthLeaseStart = datee.getMonth();
    const dayLeaseStart = detailLeaseSign.start_date
      ? detailLeaseSign.start_date.slice(8, 10)
      : "";

    const endDatee = detailLeaseSign.end_date
      ? new Date(detailLeaseSign.end_date.slice(0, 10))
      : "";
    const yearLeaseEnd = endDatee ? endDatee.getFullYear() : "";
    const monthLeaseEnd = endDatee ? endDatee.getMonth() : "";
    const dayLeaseEnd = detailLeaseSign.end_date
      ? detailLeaseSign.end_date.slice(8, 10)
      : "";

    return {
      startDate: `${dayLeaseStart}/${monthLeaseStart + 1}/${yearLeaseStart}`,
      endDate: endDatee
        ? `${dayLeaseEnd}/${monthLeaseEnd + 1}/${yearLeaseEnd}`
        : "",
    };
  };

  //function to calculate the duration which the tenant have stayed in the house
  const durationStayed = () => {
    const datee = detailLeaseSign.start_date
      ? new Date(detailLeaseSign.start_date.slice(0, 10))
      : new Date();
    const today =
      detailLeaseSign.tenant_close_deal &&
      detailLeaseSign.landlord_reject_cancel
        ? new Date(detailLeaseSign.end_date)
        : new Date();
    const yearLeaseStart = datee.getFullYear();
    const monthLeaseStart = datee.getMonth();
    const dayLeaseStart = detailLeaseSign.start_date
      ? detailLeaseSign.start_date.slice(8, 10)
      : "";
    const past = new Date(yearLeaseStart, monthLeaseStart, dayLeaseStart);

    const agreementCloseDate = tenant.lease_cancel_date
      ? new Date(tenant.lease_cancel_date)
      : "";
    let closeYear;
    let closeMonth;
    let closeDay;
    let closing;
    if (agreementCloseDate) {
      closeYear = agreementCloseDate.getFullYear();
      closeMonth = agreementCloseDate.getMonth();
      closeDay = tenant.lease_cancel_date
        ? tenant.lease_cancel_date.slice(8, 10)
        : "";
      closing = new Date(closeYear, closeMonth, closeDay);
    }

    const valueDuration = agreementCloseDate
      ? closing.getTime()
      : today.getTime();

    const diff = Math.floor(valueDuration - past.getTime());

    const day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff / day);
    const months = Math.floor(diff / (day * 30));
    return months;
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  const onSubmitEditPayment = async (e) => {
    e.preventDefault();
    if (!amountToEdit) return setEditError(t("Edited_Amount_is_not"));
    if (
      window.confirm(
        `Are you sure you want add the sum of ${commaSeparateNumber(
          amountToEdit
        )}${listing.currency} to ${capitalize(
          tenantRegisterDetails.first_name
        )}'s payment for ${capitalize(listing.title)}?`
      )
    ) {
      setOnloadEdit(true);
      const data = {
        amount: amountToEdit,
        subtractOrAdd: subtractPay ? subtractPay : addPay,
        application_id: tenant.application_id,
        application_status_id: mySignLease.id,
        lease_tenant_id: tenant.tenant_id,
        landlord_id: tenant.landlord_id,
        listing_id: listing.id,
        tenant: tenant,
        listing,
        landlord: getCurrentUser(),
        sender: tenantRegisterDetails,
      };

      socket.emit(
        `editPayment`,
        data,
        async ({
          error,
          lease_sign,
          tenanting,
          aplStatusSign,
          payments: paying,
          payDetails,
        }) => {
          setOnloadEdit(false);
          if (error) {
            setEditError(error);
            setOnloadEdit(false);
          } else {
            const myEdit = { ...mySignLease };
            myEdit.edit_amount = amountToEdit;
            setMySignLease(aplStatusSign);
            // setDetailLeaseSign(aplStatusSign)
            console.log("myEditPay", paying);
            setTenant(tenanting);
            const sortedPayments = _.orderBy(
              paying,
              [sortColumn.path],
              [sortColumn.order]
            );

            setPayments(sortedPayments);

            setPaymentDetails(payDetails);

            setEditError("");
            setSubtractPay("");
            setAddPay("add pay");
            setEditedSent(true);
            setAmountToEdit("");
            setOnloadEdit(false);

            setInterval(() => {
              setEditedSent(false);
            }, 5000);

            ///////get all tenants owing money
            const { data: ten } = await getTenantById(getCurrentUser()?.id);
            setTening(ten.filter((t) => t.amount_owed > 0));
          }
          trackEvent(
            `edit payment ${data.amount}`,
            `${data.amount}`,
            "edit payment"
          );
        }
      );
    }
  };

  const onClickPaid = () => {
    setEditedSent("");
  };

  const onClickCloseDeal = () => {
    const data = {
      landlord: getCurrentUser(),
      listing,
      tenant,
      application_sign: mySignLease,
      lease_signed: detailLeaseSign,
      tenant_space: tenantSpace,
    };
    setLoadingCloseDeal(true);
    socket.emit("landlord_closing_deal", data, ({ error }) => {
      setLoadingCloseDeal(false);
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  //tenant reject agreement Cancellation\
  const onClickRejectCancellation = () => {
    const data = {
      landlord: getCurrentUser(),
      listing,
      tenant,
      application_sign: mySignLease,
      lease_signed: detailLeaseSign,
    };
    setLoadingCloseDeal(true);

    socket.emit(
      "landlord_closing_deal_reject",
      data,
      ({ error, appl_sign, leaseSign }) => {
        setMySignLease(appl_sign);
        setDetailLeaseSign(leaseSign);
        setLoadingCloseDeal(false);
        if (appl_sign.tenant_close_deal && appl_sign.landlord_close_deal) {
          setCloseDeal(true);
        } else {
          setCloseDeal(false);
        }
      }
    );
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  const onSubmitEditEndDate = async () => {
    try {
      setLoadingEndDate(true);
      if (!updateEndDate) {
        setLoadingEndDate(false);
        return setEndDateError("Agreement end date must not be empty");
      }
      function myTestingDates() {
        const myStartDate = updateEndDate;
        const myYear = myStartDate.slice(0, 4);
        const myMonths = Number(`${myStartDate.slice(5, 7)}`) - 1;
        const myDays = Number(`${myStartDate.slice(8, 10)}`);

        const initialStartDate = detailLeaseSign.end_date;
        const initialYear = Number(initialStartDate.slice(0, 4));
        const initialMonths = Number(`${initialStartDate.slice(5, 7)}`) - 1;
        const initialDays = Number(`${initialStartDate.slice(8, 10)}`);

        const initial = new Date(initialYear, initialMonths, initialDays);
        const past = new Date(myYear, myMonths, myDays);

        const diff = Math.floor(initial.getTime() - past.getTime());
        const day = 1000 * 60 * 60 * 24;

        var days = Math.floor(diff / day);
        const months = Math.floor(days / 30);
        return days;
      }

      if (myTestingDates() > 0) {
        setLoadingEndDate(false);
        return setEndDateError(
          `Your edited agreement ending date must be greater than the initial end date ${detailLeaseSign.end_date}`
        );
      }

      const datas = {
        tenant,
        updateEndDate,
        lease_sign: detailLeaseSign,
        appl_sign: mySignLease,
      };

      const { data } = await updateLeaseEndDate(detailLeaseSign.id, datas);
      setDetailLeaseSign(data.lease);
      setTenant(data.tenant);
      setOpen(false);
      setEndDateError("");
      setLoadingEndDate(false);
      return;
    } catch (error) {
      setEndDateError(error.message);
      setLoadingEndDate(false);
    }
  };

  const onSetConfirmPay = (e) => {
    e.preventDefault();
    if (`${amountToEdit}`.startsWith("-"))
      return setEditError("Amount must be an interger");
    if (Number(amountToEdit) == 0)
      return setEditError("Amount must not be empty");
    setConfirmPassword(true);
    // onSubmitPayment
  };

  const onSubmitPassword = async () => {
    try {
      setConfirmLoading(true);
      const { data } = await userConfirmPassword({
        password: confirmPasswordData,
        listing,
      });
      setConfirmLoading(false);

      if (data) {
        setConfirmPassword(false);
        onSubmitEditPayment();
      } else {
        const chan = chances - 1;
        if (chan === 0) return history.push("/logout");
        setChances(chan);
        setErrorConfirmEdit(
          `Invalid password, please you have ${chan} chances remaining`
        );
      }
    } catch (ex) {
      if (ex.response.status === 400) setErrorConfirmEdit(ex.response.data);
    }
  };

  //////////////////////////////////////
  //download lease agreement
  const downloadAgreement = async () => {
    try {
      setLoadDownloadAgreement(true);
      await postAndDownloadAgreementService(
        tenant.application_id,
        tenant.building_name,
        (data) => setLoadDownloadAgreement(false)
      );
    } catch (error) {
      logger.log(error);
    }
  };

  ///////////////////////////////////////////////
  //edit starting date
  const onsubmitEditStartDate = async () => {
    try {
      setLoadingEditStartDate(true);

      if (!editStartingDate) {
        setLoadingEditStartDate(false);
        return setEditStartingDateError("Agreement starting must not be empty");
      }

      function myTestingDates() {
        const myStartDate = editStartingDate;
        const myYear = myStartDate.slice(0, 4);
        const myMonths = Number(`${myStartDate.slice(5, 7)}`) - 1;
        const myDays = Number(`${myStartDate.slice(8, 10)}`);

        const initialStartDate = detailLeaseSign.start_date;
        const initialYear = Number(initialStartDate.slice(0, 4));
        const initialMonths = Number(`${initialStartDate.slice(5, 7)}`) - 1;
        const initialDays = Number(`${initialStartDate.slice(8, 10)}`);

        const initial = new Date(initialYear, initialMonths, initialDays);
        const past = new Date(myYear, myMonths, myDays);

        const diff = Math.floor(initial.getTime() - past.getTime());
        const day = 1000 * 60 * 60 * 24;

        var days = Math.floor(diff / day);
        const months = Math.floor(days / 30);
        return days;
      }

      if (myTestingDates() > 0) {
        setLoadingEditStartDate(false);
        return setEditStartingDateError(
          `Your edited agreement starting date must be greater than the initial agreement starting date ${detailLeaseSign.start_date}`
        );
      }
      const datas = {
        tenant,
        detailLeaseSign,
        mySignLease,
        date: editStartingDate,
      };
      const { data } = await updateClientLeaseStartDate(datas);
      setTenant(data.tenant);
      setDetailLeaseSign(data.detailLeaseSign);
      setLoadingEditStartDate(false);
      setEditStartingDateError("");
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        setEditStartingDateError(ex.response.data);
      }
    }
  };

  if (loadMyData)
    return (
      <div className="homeSpinnerContainer">
        {/* <LottieLoader /> */}
        <ProfileSpinner />
      </div>
    );
  if (underConstruction)
    return (
      <>
        <Meta
          title={`Nawafrica|tenants details`}
          description={`Tenant Details For ${tenant.first_name}`}
        />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <>
      <EditLeaseEndDate
        loadingEndDate={loadingEndDate}
        tenantName={tenant.first_name}
        handleClose={() => setOpen(false)}
        open={open}
        onChange={(e) => setUpdateEndDate(e.target.value)}
        onSubmit={onSubmitEditEndDate}
        value={updateEndDate}
        endDateError={endDateError}
      />
      <Meta1
        title={`Nawafrica|tenants details`}
        description={`Tenant Details For ${tenant.first_name}`}
      />
      {rejectCancel && !closeDeal && (
        <AlertCustomise data={t("The Tenant Rejected the Cancellation")} />
      )}
      {requestToClose && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
            position: "fixed",
            zIndex: "5000",
          }}
        >
          <CancelAgreementAlert
            names={tenant.tenant_name}
            date={`${tenant.create_date.slice(
              8,
              10
            )}/${tenant.create_date.slice(5, 7)}/${tenant.create_date.slice(
              0,
              4
            )}`}
            listingName={t(listing.title)}
          />
        </div>
      )}

      <EditPaymentModal
        confirmPassword={confirmPassword}
        onClickConfirmEdit={onSubmitEditPayment}
        onChangeConfirmEdit={(e) => setConfirmPasswordData(e.target.value)}
        errorConfirmEdit={errorConfirmEdit}
        confirmLoadingConfirmEdit={confirmLoading}
        onloadEdit={onloadEdit}
        valueAddPay={addPay}
        onChangeAddPay={(e) => {
          const value = e.currentTarget.checked;
          if (value) {
            setAddPay("add pay");
            setSubtractPay("");
          } else {
            setAddPay("");
            setSubtractPay("subtract pay");
          }
        }}
        valueSubtractPay={subtractPay}
        onChangeSubtractPay={(e) => {
          const value = e.currentTarget.checked;
          if (value) {
            setSubtractPay("subtract pay");
            setAddPay("");
          } else {
            setSubtractPay("");
            setAddPay("add pay");
          }
        }}
        onSubmitEditPayment={onSubmitEditPayment}
        // onSubmitEditPayment={onSetConfirmPay}
        onChangeAmount={(e) => {
          setAmountToEdit(e.target.value);
        }}
        tenant={tenantRegisterDetails.first_name}
        listing={listing}
        editError={editError}
        valueAmount={amountToEdit}
        editedSent={editedSent}
        onClickPaid={onClickPaid}
        mySignLease={mySignLease}
        onChangeQuantity={(e) => {
          setQuantity(e.target.value);
          setAmountToEdit(Number(e.target.value) * Number(listing.price));
        }}
        quantity={quantity}
      />

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          onTakeBarUp={onTakeBarUp}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <div className="tenantDetailsOuterContainers">
        <Meta
          title={
            getLang() +
            t("meta_payment_payment details", {
              name: tenantRegisterDetails.first_name,
            })
          }
        />

        <div
          className={
            close
              ? "TheContainerThatTellsThatIsFlexible"
              : "TheContainerThatTellsThatIsFlexible1"
          }
        >
          {/* ////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
      THE IS SIGN THAT WILL BE SHOWN AFTER THE LANDLORD AND THE TENANT HAVE CLOSED THE DEAL */}
          {closeDeal && (
            <div
              style={{
                position: "fixed",
                marginTop: "100px",
                zIndex: "100",
                left: "17%",
              }}
            >
              <CloseDeal />
            </div>
          )}
          <div className="tenantDetailsTenantNameChatContainer">
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {tenantRegisterDetails.picture &&
              tenantRegisterDetails.picture.fileProfile &&
              tenantRegisterDetails.picture.fileProfile.public_id ? (
                <Image
                  style={{
                    height: "70px",
                    marginLeft: "10px",
                    width: "70px",
                    borderRadius: "100%",
                  }}
                  cloudName="dfbtdeliu"
                  publicId={tenantRegisterDetails.picture.fileProfile.public_id}
                />
              ) : tenantRegisterDetails.picture &&
                tenantRegisterDetails.picture.fileProfile.url ? (
                <img
                  style={{
                    height: "70px",
                    marginLeft: "10px",
                    width: "70px",
                    borderRadius: "100%",
                  }}
                  src={
                    tenantRegisterDetails.picture &&
                    tenantRegisterDetails.picture.fileProfile &&
                    tenantRegisterDetails.picture.fileProfile.url
                  }
                />
              ) : tenantRegisterDetails.picture &&
                tenantRegisterDetails.picture.fileProfile ? (
                <img
                  style={{
                    height: "70px",
                    marginLeft: "10px",
                    width: "70px",
                    borderRadius: "100%",
                  }}
                  src={
                    tenantRegisterDetails.picture &&
                    tenantRegisterDetails.picture.fileProfile
                  }
                />
              ) : (
                <FaUser className="userIcon" />
              )}

              <div
                style={{
                  marginLeft: "10px",
                  font: "normal normal bold 18px/16px Merriweather",
                }}
              >
                <b style={{ marginRight: "5px" }}>
                  {tenantRegisterDetails?.first_name}{" "}
                  {tenantRegisterDetails?.last_name}
                </b>
                {loadDownloadAgreement ? (
                  <CircularProgress size={20} />
                ) : (
                  <Tooltip
                    title={
                      t("tenant_is_occupying",{
                        tenant_name: capitalize(`${tenantRegisterDetails?.first_name}`),
                        listing_type: t(listing?.listing_type),
                        space_number: tenantSpace?.space_number,
                        listing_title: capitalize(`${listing?.title}`)
                      })
                  }
                    placement="top"
                  >
                    <IconButton onClick={downloadAgreement} type="button">
                      <Badge
                        style={{ margin: "7px" }}
                        badgeContent={tenantSpace?.space_number}
                        color="success"
                      >
                        <DownloadIcon
                          style={{ cursor: "pointer", color: "#000000" }}
                          color="action"
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>

            <div>
              <ChatManagement
                chats={
                  unreadMsgs && unreadMsgs.length > 99
                    ? "99+"
                    : unreadMsgs.length
                }
              />
            </div>
          </div>

          {/* <div className='theTenantDetailsForPaymentByTenant'> */}
          <div
            style={{
              boxShadow: "1px 1px 1px 1px #d3d3d3",
              font: "normal normal bold 14px/25px Merriweather",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={
              Number(tenant.amount_owed) <= 0
                ? "alert alert-info tenantDetailInfoContainer"
                : "alert alert-danger tenantDetailInfoContainer"
            }
          >
            {Number(tenant.amount_owed) <= 0 ? (
              <DebtFree />
            ) : (
              <OwingAnimation />
            )}
            {t("PAYMENTS_FOR_user", {
              listing: listing.title && listing.title.toUpperCase(),
            })}
          </div>

          <SearchPaymentTenant
            onChangeSearchAmount={onChangeSearchAmount}
            onChangeSearchPayDate={onChangeSearchPayDate}
            onChangeSearchPaymentMethod={onChangeSearchPaymentMethod}
            onChangeSort={onChangeSort}
            currency={listing.currency}
            closeDeal={closeDeal}
          />

          {payments.length > 0 ? (
            <TableTenantPayment
              payments={payments}
              paymentDetails={paymentDetails}
              listingCurrency={listing.currency}
              loadingSearchPayment={loadingSearchPayment}
              ondownloadReceipt={ondownloadReceipt}
              selectedPaymentToLandlord={selectedPaymentToLandlord}
              onClickPayment={onClickPayment}
              selectedPaymentTable={selectedPaymentTable}
            />
          ) : loadingSearchPayment ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              {t(".....loading")}
            </div>
          ) : (
            <div className="noListingAvailableYetCon letUsPutSomeMarginInTenantDetailsPayment">
              <div className="alert">{t("No_Payment_available")}</div>
            </div>
          )}

          {listing && (
            <ListingInfo
              loadingEndDate={loadingEndDate}
              onClickUpdateLeaseEndDate={() => setOpen(true)}
              listingAnalysis={listingAnalysis}
              onClickRejectCancellation={onClickRejectCancellation}
              onClickCloseDeal={onClickCloseDeal}
              listing={listing}
              landlordDetails={myPersonalData}
              dateLeaseStartAndExpiry={dateLeaseStartAndExpiry}
              detailLeaseSign={detailLeaseSign}
              mySignLease={mySignLease}
              monthsCover={durationStayed()}
              requestToClose={requestToClose}
              loadingCloseDeal={loadingCloseDeal}
              closeDeal={closeDeal}
              cancelDate={
                tenant && tenant.lease_cancel_date
                  ? `${
                      tenant &&
                      tenant.lease_cancel_date &&
                      tenant.lease_cancel_date.slice(8, 10)
                    }/${
                      tenant &&
                      tenant.lease_cancel_date &&
                      tenant.lease_cancel_date.slice(5, 7)
                    }/${
                      tenant &&
                      tenant.lease_cancel_date &&
                      tenant.lease_cancel_date.slice(0, 4)
                    }`
                  : null
              }
              tenantSpace={tenantSpace}
              availableSpace={availableSpace}
              onClickEdit={() => setOpenEditStartDate(true)}
              onChangeSD={(e) => {
                const { value } = e.target;
                setEditStartingDate(value);
              }}
              // valueSD,
              onsubmitEditStartDate={onsubmitEditStartDate}
              editStartingDateError={editStartingDateError}
              loadingEditStartDate={loadingEditStartDate}
            />
          )}
        </div>
      </div>
      <ScrollToTop smooth />
    </>
  );
};

export default ManageTenantDetail;
