import http from './httpService'
import {url} from "./urlServices.js"

export function registerNewsLetter(email, UploadProgress) {
  return http.post(
    '/newsletter',
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      onUploadProgress: (progress) =>
        UploadProgress(progress.loaded / progress.total),
    }
  )
}
