import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { FaTimes, FaCreditCard, FaShoppingBag } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import AddPaymentMethodModal from './AddPaymentMethodModal'
import AddPaymentSpinner from './AddPaymentSpinner'
import './leases.css'

const PaymentOptions = ({
  loadingMtnMomo,
  updatedPaymentMethod,
  onClickPaymentMethod,
  selectedPaymentMethod,
  onChangeCountryCode,
  onChangePhoneNumber,
  onhandleSubmit,
  onChangeVisaCardNumber,
  loadingVisaCard,
  onChangePaypal,
  loadingPaypal,
  valueCardNumber,
  onChangeVisaCardDate,
  valueCardDate,
  onChangeCvc,
  myPaymentMethodData,
  valueCardCvc,

  valueCountryCode,
  valuePhoneNumber,
  valuePaypal,
  addPaymentError,
  onDeletePaymentMethod,

  underConstructionBankPayment,
  underConstructionPaypal,

  onClickSelectedPayment,
}) => {
  const { t } = useTranslation()

  return (
    <div className='paymentOptionsOuterCon'>
      <AddPaymentMethodModal
        loadingMtnMomo={loadingMtnMomo}
        updatedPaymentMethod={updatedPaymentMethod}
        onClickPaymentMethod={onClickPaymentMethod}
        selectedPaymentMethod={selectedPaymentMethod}
        onChangeCountryCode={onChangeCountryCode}
        onChangePhoneNumber={onChangePhoneNumber}
        onhandleSubmit={onhandleSubmit}
        onChangeVisaCardNumber={onChangeVisaCardNumber}
        loadingVisaCard={loadingVisaCard}
        onChangePaypal={onChangePaypal}
        loadingPaypal={loadingPaypal}
        valueCardNumber={valueCardNumber}
        onChangeVisaCardDate={onChangeVisaCardDate}
        valueCardDate={valueCardDate}
        onChangeCvc={onChangeCvc}
        valueCardCvc={valueCardCvc}
        valueCountryCode={valueCountryCode}
        valuePhoneNumber={valuePhoneNumber}
        valuePaypal={valuePaypal}
        addPaymentError={addPaymentError}
        underConstructionBankPayment={underConstructionBankPayment}
        underConstructionPaypal={underConstructionPaypal}
      />

      <hr />
      <h6>{t('PAYMENT_OPTIONS')}</h6>
      <button
        className='btn paymentOptionBtn'
        data-toggle='modal'
        data-target='#exampleModalAddPaymentOption'
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
          }}
      >
        <span style={{ marginRight: '5px', fontSize: '18px' }}>
          <FaShoppingBag />
        </span>
        {t('Add_Payment_Option')}
      </button>

      {loadingVisaCard ? (
        <AddPaymentSpinner />
      ) : loadingMtnMomo ? (
        <AddPaymentSpinner />
      ) : loadingPaypal ? (
        <AddPaymentSpinner />
      ) : (
        ''
      )}

      {myPaymentMethodData.length === 0 && (
        <div className='alert alert-info ifPaymentOptionIsZero'>
          Please choose a payment option
        </div>
      )}
      <div data-simplebar className='addedPaymentOptions'>
        <ul className='list-group'>
          {myPaymentMethodData.map((method, index) => (
            <div key={method.id}>
              <li
                onClick={() => onClickSelectedPayment(method)}
                className={
                  method.active
                    ? 'list-group-item addedPaymentOptionsActive'
                    : myPaymentMethodData.filter((p) => p.active == true)
                        .length == 0 && index == 0
                    ? 'addedPaymentOptionsActive'
                    : 'list-group-item addedPaymentOptionsActive1'
                }
              >
                <div>
                  {method.payment_option === 'MTN Mobile Money' ? (
                    <img
                      style={{ objectFit: 'cover' }}
                      className='addedPaymentOptionMtnImage'
                      src='/images/mtn.png'
                    />
                  ) : method.payment_option === 'Visa Card' ? (
                    <FaCreditCard className='addedPaymentOptionVisacardIcon' />
                  ) : (
                    <img
                      style={{ objectFit: 'cover' }}
                      className='addedPaymentOptionPaypalImage'
                      src='/images/paypal.jpg'
                    />
                  )}
                </div>
                <div style={{ flex: '1' }}>
                  {method.payment_option === 'MTN Mobile Money'
                    ? method.payment_credential.momo_number
                    : method.payment_option === 'Visa Card'
                    ? method.payment_credential.card_number.replace(
                        /(\d{4}(?!\s))/g,
                        '$1 '
                      )
                    : method.payment_credential.paypal_account}
                </div>
                <div className='deletedAddedPaymentOptionSpan'>
                  <FaTimes
                    onClick={() => onDeletePaymentMethod(method)}
                    className='cancelAddedPaymentoptionIcon'
                  />
                </div>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PaymentOptions
