import React, { useRef } from "react";
import { setConfig, buildUrl } from "cloudinary-build-url";
import { Image, Video } from "cloudinary-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faXmark,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Card, Tooltip } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ReactPlayer from "react-player";

setConfig({
  cloudName: "dfbtdeliu",
});

const PictureModal1 = ({
  pictures,
  selectedImage,
  onClickCancel,
  onClickNext,
  onClickPrev,
}) => {
  const [presentingCarousel, setPresentingCarousel] = React.useState(true);
  const videoRefs = useRef([]);

  const src = buildUrl(
    selectedImage.path && selectedImage.path.secure_url
      ? selectedImage.path.secure_url
      : selectedImage.secure_url,
    {
      transformations: {
        resize: {
          type: "scale",
          width: 1200,
          height: 630,
        },
      },
    }
  );

  console.log("selectedImage ================", src);

  const onClickPresentation = () => {
    if (presentingCarousel) {
      setPresentingCarousel(false);
    } else {
      setPresentingCarousel(true);
    }
  };


  // Function to handle video click
  const handleVideoClick = (index) => {
    // Pause all videos except the clicked one
    videoRefs?.current?.forEach((video, idx) => {
      if (idx !== index && !video?.paused) {
        video?.pause();
      }
    });

    // Play the clicked video
    if (videoRefs?.current[index]?.paused) {
      videoRefs?.current[index]?.play();
    }
  };

  // console.log(pictures, selectedImage)

  let index = pictures.findIndex((element) => {
    if (element.secure_url === selectedImage.secure_url) {
      return true;
    }
  });
  if (selectedImage.title === "Parlour") index = 0;
  if (selectedImage.title === "Bedroom") index = 1;
  return (
    <div className="picModalOuterContainer1">
      <button
        onClick={onClickCancel}
        className="btn btn-light cancelImageListingDet"
      >
        <FontAwesomeIcon className="picModalCancelIcon" icon={faXmark} />
      </button>
      <Button
        onClick={onClickPresentation}
        variant="text"
        sx={{ position: "absolute", left: 20 }}
        className="btn btn-light changeImagePresentation"
      >
        <Tooltip title="Change image presentation">
          <FontAwesomeIcon className="picModalCancelIcon2" icon={faCopy} />
        </Tooltip>
      </Button>
      {presentingCarousel ? (
        <>
          <div className="picModalInnerCon1">
            <Button onClick={() => onClickPrev(index)}>
              <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            </Button>
          </div>
          {selectedImage.path && selectedImage.path.secure_url ? (
            <div className="listDetLargeImage">
              <Card
                sx={{ background: "black", color: "white" }}
                variant="outlined"
                className="listingDetNumImageCon"
              >
                {/* <b style={{ fontSize: '20px' }}> */}
                {index + 1} of {pictures.length}
                {/* </b> */}
              </Card>
              <img
                className="pictureMod1Image"
                crossOrigin="anonymous"
                // loading={() => setLoading(true)}
                // onLoad={(e) => setLoading(false)}
                src={src}
              />
            </div>
          ) : selectedImage.format === "mp4" ? (
            <div className="listDetLargeImage">
              <Card
                variant="outlined"
                sx={{ background: "black", color: "white" }}
                className="listingDetNumImageCon"
              >
                {/* <b style={{ fontSize: '20px' }}> */}
                {index + 1} of {pictures.length}
                {/* </b> */}
              </Card>
              {selectedImage.secure_url && (
                <>
                  <video
                    key={index}
                    ref={(el) => (videoRefs.current[index] = el)}
                    onClick={() => handleVideoClick(index)}
                    controls
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "black",
                      opacity: "1",
                      objectFit: "cover",
                    }}
                    className="pictureMod1Image"
                  >
                    <source src={selectedImage.secure_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {/* <Video
                       crossOrigin="anonymous"
                       playsInline
                       controls
                       ref={(el) => (videoRefs.current[index] = el)}
                       onClick={() => handleVideoClick(index)}
                       loop
                       style={{
                         width: "100%",
                         height: "100%",
                         background: "black",
                         opacity: "1",
                         objectFit: "cover",
                       }}
                       publicId={selectedImage.public_id}
                       cloudName="dfbtdeliu"
                       className="pictureMod1Image"
                     /> */}
                </>

              )}
            </div>
          ) : (
            <div className="listDetLargeImage">
              <Card
                variant="outlined"
                sx={{ background: "black", color: "white" }}
                className="listingDetNumImageCon"
              >
                {/* <b style={{ fontSize: '20px' }}> */}
                {index + 1} of {pictures.length}
                {/* </b> */}
              </Card>

              <img
                crossOrigin="anonymous"
                className="pictureMod1Image"
                src={src}
              />
            </div>
          )}
          <div className="picModalInnerCon2">
            <Button onClick={() => onClickNext(index)}>
              <FontAwesomeIcon className="icon" icon={faAngleRight} />
            </Button>
          </div>
        </>
      ) : (
        <Box className="materialUIImagePres">
          <ImageList
            // sx={{ backgroundColor: "lightgray" }}
            variant="masonry"
            cols={3}
            gap={8}
          >
            {pictures.map((item) => (
              <ImageListItem key={item.secure_url}>
                {item.format === "mp4" ? (
                  <>
                    <video
                      src={`${item.path ? item.path.secure_url : item.secure_url
                        }?w=248&fit=crop&auto=format`}
                      controls
                      style={{ width: "100%", height: "100%" }}
                    />
                  </>
                ) : (
                  <img
                    src={`${item.path ? item.path.secure_url : item.secure_url
                      }?w=248&fit=crop&auto=format`}
                    srcSet={`${item.path ? item.path.secure_url : item.secure_url
                      }?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.path ? item.path.secure_url : item.secure_url}
                    loading="eager"
                  />
                )}
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
    </div>
  );
};

export default PictureModal1;
