import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import mapStyles2 from "./mapStyles2";
import { Image } from "cloudinary-react";
import { useTranslation } from "react-i18next";
// import GoogleMark from '../../iconComponent/googleMark';
import "../SearchListing/searchListing.css";
import MapLoader from "../SearchListing/MapLoader";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const options = {
  styles: mapStyles2,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: "cooperative",
};

const SearchMapListingDet = ({
  listings,
  onMapLoad,
  keys,
  theSelected,
  onClickWindow,
  onPressImage,
}) => {
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation();
  const initialLatLng = () => {
    const latListing = listings.filter(
      (list) => list.location.coordinates.lat !== ""
    );
    return latListing[0];
  };

  const center = {
    lat: theSelected
      ? theSelected.location.coordinates.lat
      : selected
      ? selected.location.coordinates.lat
      : initialLatLng()
      ? initialLatLng().location.coordinates.lat
      : 4.0262755,
    lng: theSelected
      ? theSelected.location.coordinates.lng
      : selected
      ? selected.location.coordinates.lng
      : initialLatLng()
      ? initialLatLng().location.coordinates.lng
      : 9.1927992,
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  //

  const { isLoaded, loadError } = useLoadScript({ ...keys });

  if (loadError)
    return (
      <div
        style={{
          padding: "5px",
          background: "red",
          opacity: "0.8",
          color: "white",
          width: "50%",
          margin: "5px auto",
          textAlign: "center",
          borderRadius: "5px",
        }}
      >
        Error Loading Map
      </div>
    );
  if (!isLoaded) return <MapLoader />;

  return (
    <div className="searchListingMapCon">
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        {listings.map(
          (list) =>
            list &&
            list.location &&
            list.location.coordinates &&
            list.location.coordinates.lat && (
              <Marker
                key={list.id}
                onClick={() => setSelected(list)}
                position={{
                  lat: list.location.coordinates.lat,
                  lng: list.location.coordinates.lng,
                }}
                // onDblClick={()=>
                //  setSelected(list)
                // }
                icon={{
                  url: "/images/googleMarker.png",
                  scaledSize: new window.google.maps.Size(50, 50),

                  anchor: new window.google.maps.Point(15, 15),
                }}
              />
            )
        )}

        {theSelected &&
        theSelected.location &&
        theSelected.location.coordinates &&
        theSelected.location.coordinates.lat ? (
          <InfoWindow
            position={{
              lat: theSelected.location.coordinates.lat,
              lng: theSelected.location.coordinates.lng,
            }}
            onCloseClick={() => onClickWindow(null)}
          >
            <div
              onClick={() => onPressImage(theSelected)}
              className="windowContainerForMap1"
              style={{ cursor: "ponter" }}
            >
              {/* <Link to={`/listing/${theSelected.id}`}> */}
              <div className="imageContainer2">
                {theSelected.main_photo.path.public_id ? (
                  <Image
                    crossOrigin="anonymous"
                    className="theImageForMapsWindow2"
                    cloudName="dfbtdeliu"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    publicId={theSelected.main_photo.path.public_id}
                  />
                ) : (
                  <img
                    crossOrigin="anonymous"
                    className="theImageForMapsWindow2"
                    src={theSelected.main_photo.path.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt
                  />
                )}
              </div>
              {/* </Link> */}
              <div className="containerForTitleBedroomPriceInMap1">
                <h5 className="makeTopInCon">
                  {capitalize(theSelected.title)}
                </h5>
                <p>{`${
                  theSelected.listing_type !== "Land"
                    ? `${theSelected.bedrooms} ${t(`Bedrooms`)},`
                    : ""
                } ${t(theSelected.listing_type)} ${t("In")} ${
                  theSelected.city
                }`}</p>
                <h5>{`${theSelected.price} ${theSelected.currency}${
                  theSelected.rent_or_sell === "Rental" ? "/Mo" : ""
                }`}</h5>
              </div>
            </div>
          </InfoWindow>
        ) : selected ? (
          <InfoWindow
            position={{
              lat: selected.location.coordinates.lat,
              lng: selected.location.coordinates.lng,
            }}
            onCloseClick={() => onClickWindow(null)}
          >
            <div
              onClick={() => onPressImage(selected)}
              className="windowContainerForMap1"
            >
              {/* <Link to={`/listing/${selected.id}`}> */}
              <div className="imageContainer2">
                {selected.main_photo.path.public_id ? (
                  <Image
                    crossOrigin="anonymous"
                    className="theImageForMapsWindow2"
                    cloudName="dfbtdeliu"
                    publicId={selected.main_photo.path.public_id}
                  />
                ) : (
                  <img
                    crossOrigin="anonymous"
                    className="theImageForMapsWindow2"
                    src={selected.main_photo.path.url}
                    alt
                  />
                )}
              </div>
              {/* </Link> */}
              <div className="containerForTitleBedroomPriceInMap1">
                <h5 className="makeTopInCon">
                  {capitalize(selected.title ? selected.title : "")}
                </h5>
                <p>{`${selected.bedrooms} ${t("Bedrooms")}, ${t(
                  selected.listing_type
                )} `}</p>
                <h5>{`${selected.price} ${selected.currency}${
                  selected.rent_or_sell === "Rental" ? "/Mo" : ""
                }`}</h5>
              </div>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
};

export default SearchMapListingDet;
