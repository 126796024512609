import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import './profile.css'

const EditButton = ({ onClick, type }) => {
  const { t } = useTranslation()
  return (
    <>
      <button
        style={{
          boxShadow: '3px 1px 3px 0.5px black',
          outline: 'none',
         whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}
        type='submit'
        onClick={() => onClick(type)}
        className='editingBtn'
      >
        <span style={{ marginRight: '5px' }}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </span>
        {t('EDIT')}
      </button>
    </>
  )
}

export default EditButton
