import http from './httpService'
import {url} from "./urlServices.js"

export function postNews(data, UploadProgress) {
  return http.post('/newsroom', data, {
    'Content-Type': 'application/json',
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  })
}

export function editNews(data, id, UploadProgress) {
  return http.put(`/newsroom/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  })
}

export function getNews() {
  return http.get('/newsroom')
}

export function getNewsById(id) {
  return http.get(`/newsroom/${id}`)
}

export function deleteNewsById(id) {
  return http.delete(`/newsroom/${id}`)
}
