import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './profile.css'

const SaveButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <button
        style={{
          boxShadow: '3px 1px 3px 0.5px black',
          outline: 'none',
         whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}
        type='submit'
        onClick={onClick}
        className='editingBtn'
      >
        <span style={{ marginRight: '5px' }}>
          <FontAwesomeIcon icon={faSave} />
        </span>
        {t('SAVE')}
      </button>
    </>
  )
}

export default SaveButton
