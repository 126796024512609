import React from 'react'
import "./listingDetails.css"
import GoogleTranslate from '../../Utils/GoogleTranslate'

const Address = ({street_address}) => {
return (
<div className="addressOuterContainer">
<p>
<span>{street_address&&GoogleTranslate(street_address)}</span>
</p>
</div>
)
}

export default Address
