import React from 'react'
import Picker from 'emoji-picker-react'
import { FaPaperclip } from 'react-icons/fa'
import { FaMeh } from 'react-icons/fa'
import SpinnerPic from './../AddListing/Spinner'
import { Image } from 'cloudinary-react'
import { useTranslation } from 'react-i18next'

import { getCurrentUser } from '../../Services/authService'

import './messages.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import SentMsgLoader from './SentMsgLoader'
import SentMsgDone from './SentMsgDone'
import { Button } from '@mui/material'
import SelectedItem1Message from './SelectedItem1Message'

const ComposeMessageModal = ({
  applicationConvert,
  applications,
  ondeletePicture,
  onChangeApplicationSelect,
  onChangeSubject,
  onChangeMessage,
  onChangeImages,
  onEmojiClick,
  loadOtherPhoto,
  otherPhotoPath,
  message,
  onSubmitMessage,
  valueApplicant,
  valueSubject,
  submitError,
  loadSendingMsg,
  messageDone,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className='modal fade'
      id='exampleModalComposeMsg'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div   style={{ boxShadow: '2px 3px 5px black' }} className='modal-content modalContentInComposeMsg'>
          {loadSendingMsg ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SentMsgLoader />
            </div>
          ) : messageDone ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SentMsgDone />
            </div>
          ) : (
            <form onSubmit={onSubmitMessage}>
              <div className='modal-header'>
                <h5
                  className='modal-title myHeadingToComposeMsg'
                  id='exampleModalLabel'
                >
                  {t('Compose_Message')}
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body theBodyContainerOfComposeMsg'>
                <SelectedItem1Message
                items={applicationConvert}
                onChange={onChangeApplicationSelect}
                placeholder={t("List of applications")}
                />
                {/* <select
                  value={valueApplicant}
                  onChange={onChangeApplicationSelect}
                  type='number'
                  className='form-control selectAnApplicationInComposeMsg'
                >
                  <option value=''>{t('List_of_applications')}</option>
                  {applicationConvert.map((appl) => (
                    <option key={`${appl.id}`} value={appl.id}>
                      {appl.data}
                    </option>
                  ))}
                </select> */}
                <input
                  value={valueSubject}
                  onChange={onChangeSubject}
                  className='form-control subjectInputInComposeMsg'
                  placeholder={t('Subject')}
                />
                <textarea
                  value={message}
                  onChange={onChangeMessage}
                  placeholder={t('Text_Message')}
                  className='form-control theMessageTextAreaInComposeMsg'
                />

                <div className='imagesTosendInComposeMsg'>
                  {otherPhotoPath && (
                    <div className='fileInputMultipleImgMessage'>
                      {loadOtherPhoto && (
                        <div>
                          <SpinnerPic />
                        </div>
                      )}
                      {otherPhotoPath.map((src) => (
                        <Image
                          onClick={() => ondeletePicture(src)}
                          key={src.public_id}
                          className='overlay-image animate__animated'
                          cloudName='dfbtdeliu'
                          publicId={src.public_id}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className='footer theFooterContainerForComposeMsg'>
                <div className='sendBtnContainerComposeMsg'>
                  <Button
                   variant='contained'
                    disabled={loadSendingMsg}
                    type='submit'
                    className='btn'
                  >
                    <span style={{ marginRight: '5px' }}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                    {t('Send')}
                  </Button>
                </div>
                <div className='faPaperClipContainComposeMsg'>
                  <label htmlFor='myFileInputFieldCompose'>
                    <FaPaperclip className='clipIcon' />
                  </label>
                </div>
                <div className='faMehEmojiContainerComposeMsg'>
                  <div className='dropdown-menu'>
                    <Picker
                      onEmojiClick={(event, emojiObject) =>
                        onEmojiClick(event, emojiObject)
                      }
                    />
                  </div>
                  <a
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <FaMeh className='iconNumber2' />
                  </a>
                </div>
                <input
                  id='myFileInputFieldCompose'
                  accept='image/*'
                  className='fileUpload'
                  name='file'
                  onChange={onChangeImages}
                  type='file'
                />
              </div>
              <div className='composeMessageErrorDisplay'>{submitError}</div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ComposeMessageModal
