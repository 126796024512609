import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'cloudinary-react'
import './BlogAddStory.css'

const AddStoryFilesInput = ({
  id,
  onChange,
  accept,
  photoName,
  loading,
  error,
  uploadImage,
  label = 'Story Image',
  width = '50%',
  width2 = '',
}) => {
  const { t } = useTranslation()
  return (
    <div style={{ width: width2 }}>
      <label style={{ fontWeight: 'bold', marginTop: '7px' }} htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        onChange={onChange}
        type='file'
        className='form-control hiddleFileInputAddTenant'
        accept={accept}
      />
      <label
        style={{ width, padding: '20px 5px' }}
        className='InputLabelAddTenant'
        htmlFor={id}
      >
        <label className='innerLabelAddTenant' htmlFor={id}>
          {t('browse...')}
        </label>

        {photoName && (
          <div style={{ color: 'black' }} className='displayFileNameAddTenant'>
            {photoName}
          </div>
        )}
      </label>

      {uploadImage && (
        <Image
          className='imgAddTenant addFileImage'
          cloudName='dfbtdeliu'
          publicId={uploadImage.public_id}
        />
      )}
      {loading && (
        <div className='loadingContainerAddTenantFile'>{t('.....loading')}</div>
      )}
      {error && <p className='errorAddTenantFile'>{error}</p>}
    </div>
  )
}

export default AddStoryFilesInput
