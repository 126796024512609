import http from "./httpService"
import {url} from "./urlServices.js"

export function postingToCloudinary(data){
return http.post('/cloudinary', data, {
'Content-Type':'application/json'
})
}

export function getCloudinarySignature(){
return http.get('/cloudinarySignature')
}
