import React from "react";
import "../Listing/listing.css";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";

const SearchPayment = ({
  error,
  onChangeSearchPayer,
  onChangeSearchPayDate,
  onChangeSearchPayAmount,
  onChangeSearchListing,
  onChangeSort,
  updatedPaymentMethod,
}) => {
  const { t } = useTranslation();

  return (
    <div className="listingSearchCon3">
      <div
        style={{ marginRight: 5, marginLeft: 10 }}
        className="someMarginInListingSearchInput paymentInputFieldCon"
      >
        {error && <div className="updatePaymentMethodError">{error}</div>}
        <a
          href="#"
          className="choseAPaymentmethod"
          data-tip={`${
            updatedPaymentMethod.momo_number &&
            updatedPaymentMethod.momo_number["momo_number"]
              ? t("Now using MTN Mobile Money", {
                  number:
                    updatedPaymentMethod &&
                    updatedPaymentMethod.momo_number &&
                    updatedPaymentMethod.momo_number["momo_number"],
                })
              : updatedPaymentMethod.visa_card_number &&
                updatedPaymentMethod.visa_card_number["visa_card_number"]
              ? t("Now_using_Visa_Card", {
                  number:
                    updatedPaymentMethod.visa_card_number["visa_card_number"],
                })
              : updatedPaymentMethod.paypal_account &&
                updatedPaymentMethod.paypal_account["paypal_account"]
              ? t("Now_using_Paypal_acct", {
                  acct: updatedPaymentMethod.paypal_account["paypal_account"],
                })
              : t("Please_Select_a_payment_option")
          }`}
          data-toggle="modal"
          data-target="#exampleModalChoosePaymentMethod"
        >
          <button
            style={{
              textDecoration: "none",
              // padding: "8px 10px",
              color: "#ffa500",
              fontSize: "12px",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <FontAwesomeIcon icon={faBasketShopping} />
            </span>
            {t("Choose_Payment_Method")}
          </button>
        </a>
      </div>

      <div className="inputFieldCon paymentInputFieldCon">
        <input
          className="myListingSearchInput paymentInput"
          onChange={onChangeSearchPayer}
          placeholder={t("Search_payer")}
        />
      </div>

      <div className="searchPaymentDateContainer paymentInputFieldCon">
        <input
          className="myListingSearchInput paymentInput"
          onChange={onChangeSearchPayDate}
          placeholder={t("Search_payment_Date")}
        />
      </div>

      <div className="searchPaymentMethodInputCon paymentInputFieldCon">
        <input
          className="myListingSearchInput paymentInput"
          onChange={onChangeSearchPayAmount}
          placeholder={t("Search_Amount_Paid")}
        />
      </div>
      <div className="searchPaymentMethodInputCon paymentInputFieldCon">
        <input
          className="myListingSearchInput paymentInput"
          onChange={onChangeSearchListing}
          placeholder={t("Search_Listing")}
        />
      </div>

      <div className="listingSortDropdown paymentInputFieldCon">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select onChange={onChangeSort}>
              <option value="dateposted">{t("Date")}</option>
              <option value="payer_name">{t("Payer")}</option>
              <option value="listing">{t("Listing")}</option>
              <option value="amount">
                {t("Amount", { currency: "Price" })}
              </option>
              <option value="payment_method">{t("Method")}</option>
            </select>
          </span>
        </p>
      </div>
      <ReactTooltip effect="solid" />
    </div>
  );
};

export default SearchPayment;
