import http from "./httpService";
import {url} from "./urlServices.js"

//function to Add a Listing
export function addListing(formData, UploadProgress) {
  return http.post(`/composeListing`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
    onUploadProgress: (progress) => {
      // console.log(
      //   "progress data================",
      //   progress.loaded,
      //   progress.total,
      //   progress.loaded / progress.total,
      //   progress
      // );
      UploadProgress(progress.loaded / progress.total);
    },
  });
}

//function to edit a listing
export function editListing(formData, id, UploadProgress) {
  return http.put(`/composeListing/${id}`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  });
}

//function to get all listingd
export function getListings() {
  return http.get(`/composeListing`);
}

//function to get a listing by his id
export function getListingsById(id) {
  return http.get(`/composeListing/${id}`);
}

//function to apply for a listing
export function applyForAListing(id) {
  return http.post(`/composeListing/handleAddApplicant/${id}`);
}

//function to get all applications for a listing
export function getAllApplications(id) {
  return http.get(`/composeListing/handleAddApplicant/${id}`);
}

// function to get all applications
export function getApplications() {
  return http.get(`/composeListing/applications`);
}

//function get applications by users id
export function getApplicationsByUsersId(id) {
  return http.get(`/composeListing/applications/${id}`);
}

//Delete a given application
export function deleteApplicationById(id) {
  return http.put(`/composeListing/editDelete/${id}`);
}

//Delete a given application totally
export function deleteApplicationByIdTotally(id) {
  return http.delete(`/composeListing/applications/${id}`);
}

//Delete a given application landlord
export function deleteApplicationByIdLandlord(id) {
  return http.put(`/composeListing/editDeleteLandlord/${id}`);
}

//function to like a listing
export function likeAListing(id) {
  return http.post(`/composeListing/like/${id}`);
}

//function to get all likes for a listing
export function getAllLikes(id) {
  return http.get(`/composeListing/like/${id}`);
}

//function to report a listing
export function reportAListing(message, id) {
  return http.post(`/composeListing/report/${id}`, message, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//function to that publish or unpublish a listing
export function publishAListing(id) {
  return http.put(`/composeListing/publish/${id}`);
}

//Delete a listing
export function deleteAListing(id) {
  return http.delete(`/composeListing/${id}`);
}

//Delete a listing
export function deleteAListingByUpdateStatus(id) {
  return http.put(`/composeListing/delete/${id}`);
}

//Delete a listing
export function updateShare(id, body) {
  return http.put(`/composeListing/share/${id}`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//get all available spaces for a listing
export function clientGetAvailableSpaces(id) {
  return http.get(`/composeListing/availableSpaces/${id}`);
}

//get all available spaces
export function getClientAllAvailableSpaces() {
  return http.get(`/composeListing/availableSpaces`);
}
