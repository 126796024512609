import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Card, Chip, Tooltip } from "@mui/material";

import Carousel from "./Carousel";
import VerifiedBadge from "../Home/VerifiedBadge";
import "./searchListing.css";
import "../../components/Home/styleCompo.css";
import { getUserById } from "../../Services/registerService";
import { Regions } from "../../Utils/regions";
import CardFlag from "../Home/CardFlag";

const SearchListingDisplay = ({
  listings,
  listingFromMap,
  onClickSelectedListing,
  placeholderNumberOfListing,
  onHoverListing,
  users,
  availableSpaces = [],
}) => {
  const { t } = useTranslation();

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const calculateDate = (date) => {
    const postedDate = moment(parseInt(date));
    const nowDate = moment(Date.now());
    const diffInSec = nowDate.diff(postedDate, "seconds");
    const diffInMin = nowDate.diff(postedDate, "minutes");
    const diffInHour = nowDate.diff(postedDate, "hours");
    const diffInDay = nowDate.diff(postedDate, "days");
    const diffInMonth = nowDate.diff(postedDate, "months", true);
    const diffInYear = nowDate.diff(postedDate, "years");

    if (diffInSec < 60) return `${diffInSec} ${t("sec_ago")}`;
    if (diffInMin < 60) return `${diffInMin} ${t("min_ago")}`;
    if (diffInHour < 24) return `${diffInHour} ${t("hr_ago")}`;
    if (diffInDay < 30) return `${diffInDay} ${t("day_ago")}`;
    if (diffInMonth < 12) return `${Math.round(diffInMonth)} ${t("mth_ago")}`;

    return `${diffInYear} ${t("yr_ago")}`;
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }
  const getVerified = (item) => {
    const listOwner = users.find((u) => u.id == item.list_owner_id);
    return listOwner && listOwner.verified;
  };

  if (listings.length === 0)
    return (
      <div className="noAvailableListInfoContainerForSearchList">
        <div className="alert alert-danger">{placeholderNumberOfListing}</div>
      </div>
    );
  return (
    <Card variant="outlined" className="searchListingDisplayCon">
      <div
        style={{ paddingRight: "5px" }}
        className="row mapCardDisplayOuterCon"
      >
        {listings.map((listing) => (
          <div
            key={listing.id}
            className={
              listing === listingFromMap
                ? "searchListingDisplayCardCon1"
                : " searchListingDisplayCardCon"
            }
          >
            <div
              onMouseEnter={() => onHoverListing(listing)}
              onMouseLeave={() => onHoverListing(listing)}
              onClick={onClickSelectedListing(listing)}
              id="mapSectionListContainer"
              className="card"
              style={{ height: "100%" }}
            >
              {Regions().filter(
                (region) => region.country == listing.country
              )[0] && (
                <CardFlag
                  src={
                    Regions().filter(
                      (region) => region.country == listing.country
                    )[0].imageUrl
                  }
                />
              )}
              {getVerified(listing) && <VerifiedBadge />}
              <Carousel item={listing} listingId={listing.id} />

              <div
                type="button"
                onClick={() => onClickSelectedListing(listing)}
                className="card-body cardBodyContainerForSearchList"
              >
                <div className="title_locationCon maximumLines">
                  <h6 className="card-title card-title1">
                    <b
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        lineClamp: 2,
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {capitalize(listing.title)}
                    </b>
                  </h6>
                  <p
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 3,
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                    }}
                    className="card-text"
                  >
                    {t("displayCard_bedroom_num", {
                      bedrooms_nums:
                        listing.listing_type !== "Land" ? listing.bedrooms : "",
                      bedrooms:
                        listing.listing_type !== "Land"
                          ? `${t(`Bedrooms`)}, `
                          : "",
                      listing_type: t(listing.listing_type),
                      city: listing.city,
                    })}
                  </p>
                </div>

                <div className="price_posted">
                  <h6
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 2,
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                    className="card-title card-title2"
                  >
                    <b>{`${commaSeparateNumber(listing.price)} ${
                      listing.currency
                    }`}</b>
                  </h6>
                  <p className="card-text">
                    {t("Posted")}: {calculateDate(listing.dateposted)}
                  </p>
                  <Tooltip
                    placement="top"
                    title={
                        t("displayCard_bedroom_num_tooltip", {
                        spaces:listing?.remainingSpace > 0 ? listing?.remainingSpace : "No",
                      s:Number(listing?.remainingSpace) > 1 ? "ies" : "y",
                      include:  listing?.remainingSpace > 0? `, ${t("this include")}${listing?.remainingSpace > 1 ? "s" : ""} ${t(listing.listing_type)} #`:"",
                      availableSpaces:   listing?.remainingSpace > 0
                         ? availableSpaces
                             .sort((a, b) => a.space_number - b.space_number)
                             ?.filter((l) => l.listing_id === listing.id)
                             ?.filter((l) => l.empty_space === true)
                             .map((l) =>
                               l.space_name ? l.space_name : l.space_number
                             )
                             .join(", ")
                         : ""
                        })
                  }
                  >
                    <Chip
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      label={listing?.remainingSpace}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default SearchListingDisplay;
