import React from "react";
import { Image } from "cloudinary-react";
import { useTranslation } from "react-i18next";

import "./manageTenants.css";

const AddTenantFileInput = ({
  onChange,
  title,
  id,
  photoName = "",
  uploadedImagesArray,
  uploadImage,
  loading,
  error,
  multiple,
  accept,
  onClickImage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <label className="topLabalAddTenantFile" htmlFor={id}>
        {title}
      </label>
      <input
        id={id}
        onChange={onChange}
        type="file"
        className="form-control hiddleFileInputAddTenant"
        multiple={multiple}
        accept={accept}
      />
      <label className="InputLabelAddTenant" htmlFor={id}>
        <label className="innerLabelAddTenant" htmlFor={id}>
          {t("browse...")}
        </label>
        {photoName && (
          <div className="displayFileNameAddTenant">
            {photoName && photoName.length > 20
              ? photoName.slice(0, 20) + "..."
              : photoName}
          </div>
        )}
        {uploadedImagesArray && (
          <div className="displayFileNameAddTenant">
            {uploadedImagesArray && uploadedImagesArray.length + " files"}
          </div>
        )}
      </label>

      {uploadedImagesArray && uploadedImagesArray.length > 0 && (
        <div className="fileInputMultipleImg">
          {uploadedImagesArray.map((src) => (
            <div onClick={() => onClickImage(src)} key={src.public_id}>
              {src.filePath && src.filePath.format === "pdf" ? (
                <embed src={src.filePath.url} width="80px" height="80px" />
              ) : (
                <Image
                  crossOrigin="anonymous"
                  className="overlay-image animate__animated"
                  cloudName="dfbtdeliu"
                  publicId={src.public_id}
                />
              )}
            </div>
          ))}
        </div>
      )}

      {uploadImage && uploadImage.format === "pdf" ? (
        <img
          style={{ width: "30px", height: "30px" }}
          src="../images/pdf.png"
        />
      ) : uploadImage && uploadImage.public_id ? (
        <Image
          crossorigin="anonymous"
          className="imgAddTenant"
          cloudName="dfbtdeliu"
          publicId={uploadImage.public_id}
          style={{ objectFit: "cover" }}
        />
      ) : (
        ""
      )}
      {loading && (
        <div className="loadingContainerAddTenantFile">{t(".....loading")}</div>
      )}
      {error && <p className="errorAddTenantFile">{error}</p>}
    </>
  );
};

export default AddTenantFileInput;
