import React from "react";
import SpinnerPic from "./Spinner";
import { Image, Video } from "cloudinary-react";

import "./addListing.css";

const Input = ({
  label,
  left = "",
  myImageData,
  value,
  imgSrcOther = [],
  multiple = false,
  error,
  id,
  onChange,
  type = "text",
  accept = "",
  placeholder,
  loading,
  imgSrc,
  ondeletePicture,
  disabled,
}) => {
  return (
    <div className={left}>
      <label className="addListLabel" htmlFor={id}>
        {label}
      </label>
      <input
        value={value}
        type={type}
        className="form-control addListingInput"
        onChange={onChange}
        id={id}
        placeholder={placeholder}
        accept={accept}
        multiple={multiple}
        disabled={disabled}
      />

      <div className="fileInputImgContainer">
        {loading ? (
          <SpinnerPic />
        ) : imgSrc ? (
          <Image
            crossorigin="anonymous"
            cloudName="dfbtdeliu"
            publicId={imgSrc.public_id}
            className="fileInputImgContainer"
            crop="scale"
            style={{ objectFit: "cover" }}
          />
        ) : (
          ""
        )}
      </div>

      {error && <p className="fileInputErrorP">{error}</p>}
      {imgSrcOther && imgSrcOther.length > 0 && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="fileInputMultipleImg">
            {imgSrcOther.map((src) => (
              <div onClick={() => ondeletePicture(src)} key={src.public_id}>
                {src.format === "mp4" ? (
                  <Video
                    crossorigin="anonymous"
                    cloudName="dfbtdeliu"
                    publicId={src.public_id}
                    style={{ objectFit: "cover" }}
                  />
                ) : (
                  <img
                    style={{ objectFit: "cover" }}
                    crossorigin="anonymous"
                    src={src.url}
                  />
                )}
              </div>
            ))}
          </div>
          {loading && imgSrcOther.length === 0 && (
            <div className="addListingSpinnerContainer">
              <SpinnerPic />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
