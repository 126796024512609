import logger from '../Services/loggerService'

//function use to upload file to cloudinary
export const uploadSingleFileToCloudinary = async (image, data) => {
  try {
    const url = `https://api.cloudinary.com/v1_1/${`dfbtdeliu`}/upload`

    const { signature } = data
    const { timestamp } = data
    const { cloudinary_public_key } = data

    console.log('signature', signature, 'timestamp', timestamp, 'cloudinary_public_key', cloudinary_public_key)

    const formData = new FormData()
    formData.append('file', image)
    formData.append('signature', signature)
    formData.append('timestamp', timestamp)
    formData.append('api_key', cloudinary_public_key)

    const response = await fetch(url, {
      method: 'post',
      body: formData,
    }).catch((errors) => logger.log(errors))

    const sentData = await response.json()

    return sentData
  } catch (error) {
    console.log('error=====', error)
    logger.log(error)
  }
}

//function use to upload file to cloudinary
export const uploadMultipleFileToCloudinary = async (image, data) => {
  try {
    const url = `https://api.cloudinary.com/v1_1/${`dfbtdeliu`}/upload`

    const { signature } = data
    const { timestamp } = data
    const { cloudinary_public_key } = data

    console.log('signature', signature, 'timestamp', timestamp, 'cloudinary_public_key', cloudinary_public_key)


    const formData = new FormData()
    formData.append('file', image)
    formData.append('signature', signature)
    formData.append('timestamp', timestamp)
    formData.append('api_key', cloudinary_public_key)

    const response = await fetch(url, {
      method: 'post',
      body: formData,
    }).catch((errors) => logger.log(errors))

    const sentData = response && (await response.json())

    return sentData
  } catch (error) {
    console.log('error=====', error)
    logger.log(error)
  }
}
