import { Typography } from "@mui/material";
import React from "react";
import TitleManageTenant from "./TitleManageTenant";
import TenantAccordion from "./TenantAccordion";
import TenantSearch from "./TenantSearch";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

const TenantAnylyticManage = ({
  myTenants,
  listings,
  availableSpaces,
  users,
  onChangeListingTitle,
  onChangeListingType,
  onChangeListingCountry,
  onChangeListingCity,
  onChangeTenantName,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <TitleManageTenant
        title={t("Listing Room(s) Analytics")}
        tenants={listings.length}
      />
      <Divider style={{ width: "100%", background: "#035aa6" }} />
      <TenantSearch
        onChangeListingTitle={onChangeListingTitle}
        onChangeListingType={onChangeListingType}
        onChangeListingCountry={onChangeListingCountry}
        onChangeListingCity={onChangeListingCity}
      />

      {listings.length > 0 ? (
        <TenantAccordion
          listings={listings}
          tenants={myTenants}
          availableSpaces={availableSpaces}
          users={users}
          onChangeTenantName={onChangeTenantName}
        />
      ) : (
        <div className="noListingToAnalyse">{t("No Listing To Analyse")}</div>
      )}
    </div>
  );
};

export default TenantAnylyticManage;
