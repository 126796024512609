import http from './httpService'
import { saveAs } from 'file-saver'
import logger from './loggerService'
import {url} from "./urlServices.js"

export function getPaypalCredentials() {
  return http.get('/paypal_credentials')
}

export function payNow(data) {
  return http.post('/payment', data, {
    'Content-Type': 'application/json',
  })
}

export function paySuccessStripe(data) {
  return http.post('/payment//stripe/success', data, {
    'Content-Type': 'application/json',
  })
}

export function payNowPaypal(data) {
  return http.post('/payment/make_paypal_payment', data, {
    'Content-Type': 'application/json',
  })
}

export function payNowNonMember(data) {
  return http.post('/payment/non_member', data, {
    'Content-Type': 'application/json',
  })
}

export function getLandlordsPayments() {
  return http.get(`/payment`)
}

export function getPaymentByListing_id(id){
  return http.get(`/payment/listing/${id}`)
}

// /payer_id/:id

export function getUsersPaymentsByLeaseId(id) {
  return http.get(`/payment/${id}`)
}

export function getPaymentByPayer_id(id) {
  return http.get(`/payment/payer_id/${id}`)
}

export function getPaymentLeaseId(id) {
  return http.get(`/payment/lease-sign/${id}`)
}

export function getPaymentDetailsByApplStatusId(id) {
  return http.get(`/payment/details/${id}`)
}

export function getPaymentDetailsOflandlordById(id) {
  return http.get(`/payment/details_landlord/${id}`)
}

export function getPaymentPayerById(id) {
  return http.get(`/payment/payer/${id}`)
}

export function getPaymentDetailsPayerById(id) {
  return http.get(`/payment/payer_details/${id}`)
}

export function getPaymentingByApplicationId(id) {
  return http.get(`/payment/application/${id}`)
}

export function getPaymentByApplStatusId(id) {
  return http.get(`/payment/application_status_sign/${id}`)
}

export function postAndDownloadPaymentService(payment, setFinalise) {
  http
    .post(`/createPDF/payment/post`, payment, {
      responseType: 'blob',
    })
    .then((res) => {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
      saveAs(pdfBlob, `${payment.pdf_id}.pdf`)
      setFinalise('')
    })
    .catch((err) => {
      logger.log(
        'this is the error from getting blob in downloadAgreement',
        err
      )
    })
}

export function postAndDownloadPaymentNonmemberService(payment, setFinalise) {
  http
    .post(`/createPDF/payment_nonmember/post`, payment, {
      responseType: 'blob',
    })
    .then((res) => {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
      saveAs(pdfBlob, `${payment.pdf_id}.pdf`)
      setFinalise('')
    })
    .catch((err) => {
      logger.log(
        'this is the error from getting blob in downloadAgreement',
        err
      )
    })
}

export function downloadPaymentReceiptPDFService(payment, setFinalise) {
  http
    .get(`/createPDF/payment/${payment.id}`, { responseType: 'blob' })
    .then((res) => {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
      saveAs(pdfBlob, `${payment.pdf_id}.pdf`)
      setFinalise('')
    })
    .catch((err) => {
      logger.log(
        'this is the error from getting blob in downloadAgreement',
        err
      )
    })
}
