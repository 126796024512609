import React, { useEffect, useState } from "react";
import { FaRegHeart, FaHeart, FaUser } from "react-icons/fa";
import { Image } from "cloudinary-react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import "./listingDetails.css";
import LikeListing from "./LikeListing";

const OwnerOtherDetails = ({
  src,
  user,
  timeListed,
  numOfAppl,
  numOfLikes,
  liked,
  handleLike,
  id,
  listing,
  onShareWindow,
  share,
  likings,
}) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);

  const onClickShare = () => {
    if (hidden) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  };

  document.getElementById("detailsHousesFoundHere") &&
    document
      .getElementById("detailsHousesFoundHere")
      .addEventListener("dblclick", (e) => {
        setHidden(false);
      });
  return (
    <>
      {hidden && (
        <div id="share" className="share">
          <div>
            <FacebookShareButton
              onShareWindowClose={onShareWindow}
              imageUrl={`${listing.main_photo.path.url}`}
              title={`${`${listing.bedrooms} bedroom(s),`} ${listing.listing_type
                } in ${listing.city}, ${listing.country}`}
              url={`https://www.nawafrica.com/listing/${id}`}
              quote={`${listing.bedrooms} bedroom(s), ${listing.listing_type} in ${listing.city}, ${listing.country}`}
              hashtag={`#####${listing.listing_type}For${listing.rent_or_sell}${listing.city}${listing.country}`}
            >
              <FacebookIcon onClick={onClickShare} size={40} round={true} />
            </FacebookShareButton>
          </div>

          <div>
            <FacebookMessengerShareButton
              appId="809603507085066"
              onShareWindowClose={onShareWindow}
              imageUrl={`${listing.main_photo.path.url}`}
              title={`${`${listing.bedrooms} bedroom(s),`} ${listing.listing_type
                } in ${listing.city}, ${listing.country}`}
              url={`https://www.nawafrica.com/listing/${id}`}
              quote={`${listing.bedrooms} bedroom(s), ${listing.listing_type} in ${listing.city}, ${listing.country}`}
              hashtag={`#####${listing.listing_type}For${listing.rent_or_sell}${listing.city}${listing.country}`}
            >
              <FacebookMessengerIcon
                onClick={onClickShare}
                size={40}
                round={true}
              />
            </FacebookMessengerShareButton>
          </div>

          <div>
            {" "}
            <TwitterShareButton
              onShareWindowClose={onShareWindow}
              imageUrl={`${listing.main_photo.path.url}`}
              title={`${`${listing.bedrooms} bedroom(s),`} ${listing.listing_type
                } in ${listing.city}, ${listing.country}`}
              url={`https://www.nawafrica.com/listing/${id}`}
              quote={`${listing.bedrooms} bedroom(s), ${listing.listing_type} in ${listing.city}, ${listing.country}`}
              hashtag={`#####${listing.listing_type} for ${listing.rent_or_sale}`}
            >
              <TwitterIcon onClick={onClickShare} size={40} round={true} />
            </TwitterShareButton>
          </div>
          <div>
            {" "}
            <WhatsappShareButton
              onShareWindowClose={onShareWindow}
              imageUrl={`${listing.main_photo.path.url}`}
              title={`${`${listing.bedrooms} bedroom(s),`} ${listing.listing_type
                } in ${listing.city}, ${listing.country}`}
              url={`https://www.nawafrica.com/listing/${id}`}
              quote={`${listing.bedrooms} bedroom(s), ${listing.listing_type} in ${listing.city}, ${listing.country}`}
              hashtag={`#####${listing.listing_type} for ${listing.rent_or_sale}`}
            >
              <WhatsappIcon onClick={onClickShare} size={40} round={true} />
            </WhatsappShareButton>
          </div>
          <div>
            {" "}
            <EmailShareButton
              onShareWindowClose={onShareWindow}
              imageUrl={`${listing.main_photo.path.url}`}
              subject={`${`${listing.bedrooms} bedroom(s),`} ${listing.listing_type
                } in ${listing.city}, ${listing.country}`}
              url={`https://www.nawafrica.com/listing/${id}`}
              body={listing.details}
            >
              <EmailIcon onClick={onClickShare} size={40} round={true} />
            </EmailShareButton>
          </div>
        </div>
      )}
      <div className="ownerOtherDetCon">
        <div className="listedByCon">
          <p className="listedByParaTop">{t("Listed_By")}</p>
          {src && src.fileProfile && src.fileProfile.api_key ? (
            <img
              crossOrigin="anonymous"
              src={src.fileProfile.secure_url}
              style={{ backgroundColor: "lightgray" }}
            />
          ) : src.fileProfile && src.fileProfile ? (
            <img
              style={{ objectFit: "cover", backgroundColor: "lightgray" }}
              crossOrigin="anonymous"
              src={src.fileProfile}
              alt="owner_image"
            />
          ) : (
            ""
          )}
          {!src && (
            <div className="noImageListingDetCon">
              <FaUser className="userIcon" />
            </div>
          )}
          <p className="usernamePara">{user}</p>
        </div>

        <div className="listedSinceCon">
          <p className="listedSinceParaTop">{t("Listed_since")}</p>
          <p className="timePara">{timeListed}</p>
        </div>

        <div className="applRecievedCon">
          <p className="applRecievePara">{t("Applications_recieved")}</p>
          <p className="numOfAppPara">
            {numOfAppl < 1000 ? numOfAppl : numOfAppl / 1000 + "k"}
          </p>
        </div>

        <div className="likersCon">
          <p className="likesAndHeatsPara">
            {t("Likes")}

            <button
              style={{ borderRadius: "100%", padding: "7px" }}
              onClick={handleLike}
              className="btn btn-light"
            >
              {liked && likings && <LikeListing />}
              {liked && !likings ? <FaHeart className="fullHeartClass" /> : ""}

              {!liked && <FaRegHeart className="emtyHeartStyle" />}
            </button>
          </p>
          <p className="numOfLikesPaara">
            {numOfLikes < 1000 ? numOfLikes : numOfLikes / 1000 + "k"}
          </p>
        </div>

        <div className="likersCon shareContainer">
          {share !== 0 && (
            <span
              style={{
                color: "#707070",
                opacity: "0.8",
                fontSize: '12px',
              }}>
              {share < 1000 ? share : share / 1000 + "k"}
            </span>
          )}
          <button
            style={{ borderRadius: "100%", padding: "7px", margin: "0 5px" }}
            onClick={onClickShare}
            className="btn btn-light"
          >
            <FontAwesomeIcon
              style={{
                color: "#707070",
                fontSize: "18px",
                // marginBottom: '20px',
                cursor: "pointer",
                opacity: "0.8",
              }}
              icon={faShare}
            />
          </button>
          <span
            style={{
              color: "#707070",
              opacity: "0.8",
              fontSize: '12px',

            }}>{t("Share")}</span>
          {/* <FacebookShareCount url={`https://www.nawafrica.com/listing/${id}`}>
            {(shareCount) => (
              <span
                style={{ color: 'black', background: 'red' }}
                className='myShareCountWrapper'
              >

                {shareCount}
              </span>
            )}
          </FacebookShareCount> */}
        </div>
      </div>
    </>
  );
};

export default OwnerOtherDetails;
