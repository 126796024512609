import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import _ from "lodash";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import SearchInputs from "../components/SearchListing/SearchInputs";
import SearchListingDisplay from "../components/SearchListing/SearchListingDisplay";
import SearchMap from "../components/SearchListing/SearchMap";
import ProfileSpinner from "../components/profile/ProfileSpinner";

import selectedCountryInputs from "../Utils/translateInput";
import { paginate } from "../Utils/pagination";
import { Countries } from "../Utils/regions";
import { countries } from "../Utils/countries";
import { Regions } from "../Utils/regions";
import Meta1 from "../Utils/Meta1";
//import Meta from './../Utils/Meta'
import { getClientAllAvailableSpaces, getListings } from "../Services/listings";

import "../components/SearchListing/searchListing.css";
import PaginationMUI from "../components/Home/PaginationMUI";
import { getUsers } from "../Services/registerService";
import formatArrayCount from "../Utils/convertArrayToKMB";

const SearchListing = ({ location }) => {
  const { t } = useTranslation();

  const [listings, setListings] = useState([]);
  const [myQueryData, setMyQueryData] = useState({});

  const [selectedAfricaCountry, setSelectedAfricaCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBedroom, setSelectedBedroom] = useState("");
  const [selectedListing, setSelectedListing] = useState("");
  const [rentOrSell, setRentOrSell] = useState("");

  const [keyword, setKeyword] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const [price, setPrice] = useState("");
  const [listingFromMap, setListingFromMap] = useState({});

  const [loadingListings, setLoadingListings] = useState(false);
  const [error, setError] = useState("");

  const [keys, setKey] = useState("");

  const [sortColumn, setSortColumn] = useState({
    path: "title",
    order: "asc",
  });

  const [sortColumnListing, setSortColumnListing] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [theSelected, setTheSelected] = useState(null);

  const [langType, setLangType] = useState("");
  const [users, setUsers] = useState([]);

  const [availableSpaces, setAvailableSpaces] = useState([]);

  const [title, setTitle] = useState("");

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const Keywords = [
      "Pool",
      "Flowers",
      "Garden",
      "Playground",
      "Gym",
      "Balcony",
      "Air condition",
      "Furnished",
      "Inner Toilet",
      "Inner Kitchen",
      "Inner Bathroom",
      "Constant Water Supply",
      "Electricity",
      "Security",
      "Fence",
      "Internet",
      "Cable TV",
      "Parking",
      "Central Town",
      "Near School",
      "Near Hospital",
      "Near Market",
      "Near Police Station",
      "Near Bus Station",
      "Near Airport",
      "Near Beach",
      "Friendly Neighborhood",
      "Quiet Neighborhood",
      "Near Main Road",
    ];
    setKeyword(Keywords.sort());
    getData();
    localStorage.setItem("portalOrManagement", "");
  }, [location]);

  //function to get all searched data
  const getData = async () => {
    try {
      setLangType(cookies.get("i18next") || "en");
      setLoadingListings(true);
      const queryData = queryString.parse(location.search);
      setMyQueryData(queryData);
      setSelectedAfricaCountry(queryData.country);
      setSelectedCity(queryData.city);
      setSelectedBedroom(queryData.bedrooms);
      setPrice(queryData.price);
      setSelectedListing(queryData.listing_type);
      setRentOrSell(queryData.rentOrSell);
      setSelectedKeyword(
        queryData.keyword.split(",") && queryData.keyword.split(",")[0] === ""
          ? []
          : queryData.keyword.split(",")
      );
      if (queryData?.title) {
        setTitle(queryData.title);
      }

      const { data } = await getListings();
      const { data: spaces } = await getClientAllAvailableSpaces();
      setAvailableSpaces(spaces);
      const publishData = data.filter((d) => d.publish === "publish");
      const allUndeletedListing = publishData.filter(
        (listing) => listing.status !== "deleted"
      );
      const sortedListing = _.orderBy(
        allUndeletedListing,
        [sortColumnListing.path],
        [sortColumnListing.order]
      );
      let sortedListing1 = [];

      for (let i = 0; i < sortedListing.length; i++) {
        let list = sortedListing[i];
        list.remainingSpace = spaces.filter(
          (space) => space.listing_id === list.id && space.empty_space == true
        ).length;
        sortedListing1.push(list);
      }

      setListings([
        ...sortedListing1.filter((listing) => listing.remainingSpace > 0),
        ...sortedListing1.filter((listing) => listing.remainingSpace === 0),
      ]);
      const { data: keys } = await axios.get("/map");
      setKey({ googleMapsApiKey: keys, libraries: ["places"] });
      const { data: userss } = await getUsers();
      setUsers(userss);

      setLoadingListings(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        setLoadingListings(false);
        setError(ex.response.data);
      }
    }
  };

  //function use for filtering and paginating listings
  const getPagedData = () => {
    let filtered = [...listings];
    if (selectedBedroom) {
      filtered = filtered.filter(
        (filter) => filter.bedrooms === selectedBedroom
      );
    }

    if (selectedListing) {
      filtered = filtered.filter(
        (filter) => filter.listing_type === selectedListing
      );
    }

    if (price) {
      filtered = filtered.filter(
        (filter) => Number(filter.price) >= Number(price.trim())
      );
    }

    if (selectedKeyword.length > 0) {
      filtered = filtered.filter((filter) =>
        filter.keyword.some((r) => selectedKeyword.includes(r))
      );
    }

    if (selectedCity) {
      filtered = filtered.filter(
        (filter) => filter.city.trim() === selectedCity.trim()
      );
    }

    if (selectedState) {
      filtered = filtered.filter((filter) => filter.state === selectedState);
    }

    if (selectedAfricaCountry) {
      filtered = filtered.filter(
        (filter) => filter.country === selectedAfricaCountry
      );
    }

    if (rentOrSell) {
      filtered = filtered.filter(
        (filter) => filter.rent_or_sell === rentOrSell
      );
    }

    if (title) {
      filtered = filtered.filter((filter) =>
        filter.title.toLowerCase().includes(title.toLowerCase())
      );
    }

    const items = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  //function to shift the map to a given postion
  const panTo = React.useCallback(({ lat, lng }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
  }, []);

  //function that is call when a page is click
  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  //function to go to next page when presses the next button
  const nextPage = () => {
    let currentPages = currentPage;
    currentPages = currentPages + 1;
    setCurrentPage(currentPages);
  };

  //function to go to previous page when press the previous button
  const previousPage = () => {
    let count = currentPage;
    count = currentPage - 1;
    count = count <= 1 ? 1 : count;
    setCurrentPage(count);
  };

  const onClickSearch = () => {
    window.location = `/search_listing?country=${selectedAfricaCountry}&state=${selectedState}&city=${selectedCity}&bedrooms=${selectedBedroom}&listing_type=${selectedListing}&keyword=${selectedKeyword}&price=${price}`;
  };

  const onClickHouse = (listing) => {
    const listingArr = [...listings];
    const filteredListing = listingArr.filter((list) => list !== listing);
    filteredListing.unshift(listing);
    setListings(filteredListing);
    setListingFromMap(listing);
  };

  //function to sort the searched listing base on a parameter
  const onChangeSort = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    const isListings = [...listings];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedListing = _.orderBy(isListings, [column.path], [column.order]);
    setListings(sortedListing);
  };

  //function to shift the map to a given location when select a given listing
  const onClickSelectedListing = (listing) => {
    panTo({
      lat: listing.location.coordinates.lat
        ? listing.location.coordinates.lat
        : 4.0262755,
      lng: listing.location.coordinates.lng
        ? listing.location.coordinates.lng
        : 9.1927992,
    });
  };

  const { totalCount, data: items } = getPagedData();

  const onHoverListing = (data) => {
    setTheSelected(data);
  };

  const onCloseClick = () => {
    setTheSelected(null);
  };

  if (loadingListings || !keys)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  if (error)
    return (
      <div className="homeSpinnerContainer">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  return (
    <div style={{ width: "100%", position: "relative", height: "auto" }}>
      <Meta1
        title={`${langType}.${t("meta_search_list")}`}
        description="Search Houses and Lands In Africa With Google Map"
        link="/search_listing?country=Cameroon&state=&city=Limbe&bedrooms=&keyword=&listing_type=&price=&rentOrSell="
      />

      <div className="searchListingConMapSearch">
        <SearchInputs
          countries={countries()}
          onChangeCountry={(e, values) => {
            let value = values ? values.label.trim() : "";

            if (value === "All Countries" || !value) {
              setSelectedCity("");

              setSelectedState("");

              setSelectedListing("");

              setSelectedBedroom("");

              setPrice("");

              setSelectedKeyword([]);

              setSelectedAfricaCountry("");

              setCurrentPage(1);

              return;
            }
            setCurrentPage(1);
            let state = Regions();

            const Citiess =
              value !== ""
                ? state.filter((s) => s.country === value)[0].cities
                : [];
            setSelectedCity("");
            setSelectedBedroom("");
            setSelectedListing("");
            setSelectedKeyword([]);
            setCities(Citiess);
            setSelectedAfricaCountry(value);
          }}
          valueCountry={selectedAfricaCountry}
          cities={cities}
          placeholderPrice={
            listings[0]
              ? `${t("Max_Price")} ${items[0] ? items[0].currency : ""}`
              : ""
          }
          valueCity={selectedCity}
          onChangePrice={(e) => {
            setCurrentPage(1);
            setPrice(e.currentTarget.value);
          }}
          valuePrice={`${price}`}
          onChangeEmpty1={(e, values) => {
            let value = values ? Number(values) : "";
            if (value > 0) {
              setCurrentPage(1);
              setSelectedBedroom(`${value}`);
            } else {
              setCurrentPage(1);
              setSelectedBedroom("");
            }
          }}
          placeholderEmpty2=""
          onChangeEmpty2={(e) => {
            e.preventDefault();
            setCurrentPage(1);
            const { value } = e.currentTarget;
            setSelectedListing(value);
          }}
          onChangeCity={(e, values) => {
            let value = values ? values.trim() : "";
            if (value) {
              setCurrentPage(1);
              setSelectedCity(value);
            } else {
              setCurrentPage(1);
              setSelectedCity("");
            }
          }}
          onChangeRentOrSale={(e) => {
            setCurrentPage(1);
            setRentOrSell(e.currentTarget.value);
          }}
          listingType={[
            "Single family house",
            "Town house",
            "Apartment",
            "Studio",
            "Single Room",
            "Office",
            "Store/Shop",
            "Land",
            "Condos",
          ]}
          bedrooms={[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
            37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
            54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
            71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
            88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103,
            104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
            117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129,
            130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
            143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155,
            156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168,
            169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181,
            182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194,
            195, 196, 197, 198, 199, 200,
          ]}
          placeholderNumberOfListing={t("search_list_bar", {
            number: totalCount === 0 ? t("No") : formatArrayCount(items),
            listing: selectedListing ? selectedListing : t(`Listings`),
            country: `${
              selectedAfricaCountry
                ? `${selectedCountryInputs(selectedAfricaCountry)}${
                    selectedCity ? `, ${selectedCity}` : ""
                  }`
                : selectedAfricaCountry === ""
                ? t("Nawafrica Marketplace")
                : `${t("In")} ${myQueryData.country}`
            }`,
            rental: `${rentOrSell && `${t("For")} ${t(rentOrSell)}`}`,
          })}
          valuebedroom={selectedBedroom}
          valueListingType={selectedListing}
          onClickSearch={onClickSearch}
          onChangeSort={onChangeSort}
        />

        <div className="searchListingMapAndListingCon">
          <div className="onlyMapCon">
            {keys && (
              <SearchMap
                valueTitle={title}
                listings={items}
                onClickHouse={onClickHouse}
                onMapLoad={onMapLoad}
                keys={keys}
                theSelected={theSelected}
                onCloseClick={onCloseClick}
                keywords={keyword}
                keyword={selectedKeyword}
                handleChange={(e, values) => {
                  const {
                    target: { value },
                  } = e;
                  setCurrentPage(1);
                  setSelectedKeyword(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                onChangeTitle={(e) => setTitle(e.currentTarget.value)}
              />
            )}
          </div>

          <div className="onlyListingsCon">
            <SearchListingDisplay
              availableSpaces={availableSpaces}
              users={users}
              onHoverListing={onHoverListing}
              listings={items}
              myQueryData={myQueryData}
              selectedAfricaCountry={selectedAfricaCountry}
              selectedCity={selectedCity}
              listingFromMap={listingFromMap}
              onClickSelectedListing={onClickSelectedListing}
              placeholderNumberOfListing={t("search_list_bar", {
                number: totalCount === 0 ? t("No") : formatArrayCount(items),
                listing: selectedListing ? selectedListing : t(`Listings`),
                country: `${
                  selectedAfricaCountry
                    ? `${t("In")} ${selectedCountryInputs(
                        selectedAfricaCountry
                      )}${selectedCity ? `, ${selectedCity}` : ""}`
                    : selectedAfricaCountry === ""
                    ? "Nawafrica Marketplace"
                    : `${t("In")} ${myQueryData.country}`
                }`,
                rental: `${rentOrSell && `${t("For")} ${t(rentOrSell)}`}`,
              })}
            />
          </div>
        </div>
      </div>
      <div className="myPaginationContainerSearch">
        <PaginationMUI
          count={Math.ceil(totalCount / pageSize)}
          onChange={handlePageChange}
        />
        {/* <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          nextPage={nextPage}
          previousPage={previousPage}
        /> */}
      </div>
      <ScrollToTop smooth />
      {/* <div className="letMeGiveSomeSeparationOverAccountable"></div> */}
    </div>
  );
};

export default SearchListing;
