import http from "./httpService"
import {url} from "./urlServices.js"

export function createYourPDF(data){
return http.post('/createPDF', data)
}

export function createPDFForTenant(data){
return http.post('/createPDF/tenant', data, {responseType:'blob'})
 }
