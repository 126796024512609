import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import { Image } from "cloudinary-react";
import { useTranslation } from "react-i18next";
// import GoogleMark from '../../iconComponent/googleMark';
import "./searchListing.css";
import MapLoader from "./MapLoader";
import HouseAnimation from "./HouseAnimation";
import { Card } from "@mui/material";
import TextInputKeywordSearch from "./TextInputKeyword";
import { Regions } from "../../Utils/regions";
import CardFlag from "../Home/CardFlag";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: "cooperative",
};

const SearchMap = ({
  listings,
  onMapLoad,
  keys,
  theSelected,
  onCloseClick,
  keyword,
  keywords,
  handleChange,
  onChangeTitle,
  valueTitle,
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);
  const initialLatLng = () => {
    const latListing = listings.filter(
      (list) => list.location.coordinates.lat !== ""
    );
    return latListing[0];
  };

  const center = {
    lat: theSelected
      ? theSelected.location.coordinates.lat
      : selected
      ? selected.location.coordinates.lat
      : initialLatLng()
      ? initialLatLng().location.coordinates.lat
      : 4.0262755,
    lng: theSelected
      ? theSelected.location.coordinates.lng
      : selected
      ? selected.location.coordinates.lng
      : initialLatLng()
      ? initialLatLng().location.coordinates.lng
      : 9.1927992,
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  //

  const { isLoaded, loadError } = useLoadScript({ ...keys });

  if (loadError)
    return (
      <div
        style={{
          padding: "5px",
          background: "red",
          opacity: "0.8",
          color: "white",
          width: "60%",
          margin: "5px auto",
          textAlign: "center",
          borderRadius: "5px",
        }}
      >
        Error Loading Map, Please Check Your Network And Reload
      </div>
    );
  if (!isLoaded) return <MapLoader />;

  return (
    <Card variant="outlined" className="searchListingMapCon">
      <h1>
        <HouseAnimation />
      </h1>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        <TextInputKeywordSearch
          items={keywords}
          placeholder={t("keywords_(Any)")}
          keyword={keyword}
          handleChange={handleChange}
          onChangeTitle={onChangeTitle}
          valueTitle={valueTitle}
        />
        {listings.map(
          (list) =>
            list &&
            list.location &&
            list.location.coordinates &&
            list.location.coordinates.lat && (
              <Marker
                key={list.id}
                onClick={() => setSelected(list)}
                position={{
                  lat: list.location.coordinates.lat,
                  lng: list.location.coordinates.lng,
                }}
                // onDblClick={()=>
                //  setSelected(list)
                // }
                icon={{
                  url: "/images/googleMarker.png",
                  scaledSize: new window.google.maps.Size(50, 50),

                  anchor: new window.google.maps.Point(15, 15),
                }}
              />
            )
        )}

        {theSelected &&
        theSelected.location &&
        theSelected.location.coordinates &&
        theSelected.location.coordinates.lat ? (
          <InfoWindow
            position={{
              lat: theSelected.location.coordinates.lat,
              lng: theSelected.location.coordinates.lng,
            }}
            // onCloseClick={() => {
            //   setSelected(null);
            // }}
            onCloseClick={onCloseClick}
          >
            <div className="windowContainerForMap">
              <Link
                style={{
                  width: "100%",
                  height: "80%",
                  backgroundColor: "lightgray",
                }}
                to={`/listing/${theSelected.id}`}
              >
                {Regions().filter(
                  (region) => region.country == theSelected.country
                )[0] && (
                  <CardFlag
                    src={
                      Regions().filter(
                        (region) => region.country == theSelected.country
                      )[0].imageUrl
                    }
                  />
                )}
                <div className="imageContainer">
                  {theSelected.main_photo.path.public_id ? (
                    <Image
                      crossorigin="anonymous"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      className="theImageForMapsWindow1"
                      cloudName="dfbtdeliu"
                      publicId={theSelected.main_photo.path.public_id}
                    />
                  ) : (
                    <img
                      crossorigin="anonymous"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      className="theImageForMapsWindow1"
                      src={theSelected.main_photo.path.url}
                      alt
                    />
                  )}
                </div>
              </Link>
              <div className="containerForTitleBedroomPriceInMap">
                <h5>{capitalize(theSelected.title)}</h5>
                <p>
                  {
                    t("the_number_of_bedroom", {
                      bedrooms:
                        theSelected.listing_type !== "Land"
                          ? `${theSelected.bedrooms} ${t(`Bedrooms`)}`
                          : "",
                      listing_type: t(theSelected.listing_type),
                      city: theSelected.city,
                    })
                    //  `${theSelected.listing_type!=='Land'?`${theSelected.bedrooms} Bedrooms`:""} ${theSelected.listing_type} in ${theSelected.city}`
                  }
                </p>
                <h5>{`${commaSeparateNumber(theSelected.price)} ${
                  theSelected.currency
                }${theSelected.rent_or_sell === "Rental" ? "/Mo" : ""}`}</h5>
              </div>
            </div>
          </InfoWindow>
        ) : selected ? (
          <InfoWindow
            position={{
              lat: selected.location.coordinates.lat,
              lng: selected.location.coordinates.lng,
            }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div className="windowContainerForMap">
              <Link
                style={{ width: "100%", height: "80%" }}
                to={`/listing/${selected.id}`}
              >
                <div className="imageContainer">
                  {selected.main_photo.path.public_id ? (
                    <Image
                      crossorigin="anonymous"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      className="theImageForMapsWindow1"
                      cloudName="dfbtdeliu"
                      publicId={selected.main_photo.path.public_id}
                    />
                  ) : (
                    <img
                      crossorigin="anonymous"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      className="theImageForMapsWindow1"
                      src={selected.main_photo.path.url}
                      alt
                    />
                  )}
                </div>
              </Link>
              <div className="containerForTitleBedroomPriceInMap">
                <h5>{capitalize(selected.title ? selected.title : "")}</h5>
                <p>{`${selected.bedrooms} ${t(`Bedrooms`)} ${t(
                  selected.listing_type
                )} `}</p>
                <h5>{`${commaSeparateNumber(selected.price)} ${
                  selected.currency
                }${selected.rent_or_sell === "Rental" ? "/Mo" : ""}`}</h5>
              </div>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </Card>
  );
};

export default SearchMap;
